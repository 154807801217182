const NavItems = [
    {
        id:"1",
        hasSubMenu: true,
        navItem: "产品",
        path: "/Product",
        NavItems:[
            {
                id:"2",
                hasSubMenu: false,
                navItem: "鲁班 Motor 开发组件",
                path: "/Product/Motor_SDK",
            },
            {
                id:"3",
                hasSubMenu: false,
                navItem: "鲁班 Builder 开发组件",
                path: "/Product/Builder_SDK",
            }
        ]
    },
    {
        id:"4",
        hasSubMenu: false,
        navItem: "案例",
        path: "/Case",
    },
    {
        id:"5",
        hasSubMenu: false,
        navItem: "示例",
        path: "/Example",
    },
    // {
    //     id:"6",
    //     hasSubMenu: false,
    //     navItem: "价格",
    //     path: "/Price",
    // },
    {
        id:"7",
        hasSubMenu: false,
        navItem: "开发文档",
        path: "/Documentation",
    }
]

export {NavItems}