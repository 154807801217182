import * as React from "react"
import MarkDown from '../Markdown/Markdown'
import LBDP_ContentTitle from '../ContentTitle/ContentTitle'
import MotorPriceKit from './MotorPriceKit'
import BuilderPriceKit from './BuilderPriceKit'
import ServiceKit from './ServiceKit'
import '../../asset/components/Price/style/Price.less'

const PriceConfig = require('priceConfig')

export default class Price extends React.Component {
    constructor(props: any) {
        super(props);
    }
    
    public render(): JSX.Element {
        return (
            <div className='ComponentStyle' style={{marginBottom: 140}}>
                <div className='Banner'>
                    <div className='Container'>
                        <MarkDown className='Info'>{PriceConfig.brief}</MarkDown>
                    </div>
                </div>
                <div className='MotorPrice'>
                    <div className='TitleContainer'>
                        <LBDP_ContentTitle text='Motor开发组件' english='Developer Components' />
                    </div>
                    <MotorPriceKit data={PriceConfig.MotorPriceData}/>
                </div>
                <div className='BuilderPrice' style={{backgroundColor: '#fafafa'}}>
                    <div className='TitleContainer'>
                        <LBDP_ContentTitle text='Builder开发组件' english='Developer Components' />
                    </div>
                    <BuilderPriceKit data={PriceConfig.BuilderPriceData}/>
                </div>
                <div className='Service' >
                    <div className='TitleContainer'>
                        <LBDP_ContentTitle text='定制服务' english='Customized Service' />
                    </div>
                    <ServiceKit data={PriceConfig.ServiceData}/>
                </div>
            </div>
        );
    }
}