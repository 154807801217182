import * as React from "react"
import MarkDown from '../Markdown/Markdown'
import LBDP_ContentTitle from '../ContentTitle/ContentTitle'
import CaseKit from './CaseKit'
import '../../asset/components/Case/style/case.less'

const CaseConfig = require('caseConfig')

export default class Case extends React.Component {
    constructor(props: any) {
        super(props);
    }
    
    public render(): JSX.Element {
        return (
            <div className='ComponentStyle' style={CaseConfig.CaseConfig.length % 2 == 0 ? {} : {marginBottom:100}}>
                <div className='Banner'>
                    <div className='Container'>
                        <MarkDown className='Info'>{CaseConfig.brief}</MarkDown>
                    </div>
                </div>
                <div className='Case'>
                    <div className='TitleContainer CaseTitleContainer'>
                        <LBDP_ContentTitle text='平台案例' english='Platform case' />
                    </div>
                    <CaseKit data={CaseConfig.CaseConfig}/>
                </div>
            </div>
        );
    }
}
