import * as React from 'react'
import { makeStyles, createStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import ConnectButton from './connectButton'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            width: 1180,
            margin:'0 auto'
        },
        Info: {
            width: 164,
            height: 119,
        },
        buttonDiv : {
            width: 200,
            height: 44,
            margin:'0 auto',
            marginTop: 70
        },
        button :{
            width: 200,
            height: 44,
            backgroundImage: 'linear-gradient(90deg, #ff2a54 0%, rgba(241, 54, 77, 0.24) 50%, #ed3a4b 66%, #e34146 100%), linear-gradient(#e34147, #e34147)',
            backgroundBlendMode: 'normal, normal',
            borderRadius: '2px',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 'normal',
            letterSpacing: '0px',
            color: '#ffffff',
        },
        buttonTel:{
            width:200,
            height:44,
            borderStyle: 'solid',
	        borderWidth: '1px',
	        borderColor: 'rgba(227, 65, 71, .5)',
            fontSize: '16px',
	        fontWeight: 'normal',
	        fontStretch: 'normal',
	        letterSpacing: '1px',
	        color: '#e34147',
	        '&:hover':{
                backgroundColor: 'rgba(227, 65, 71, 0.2)'
            },
            "& span": {
                fontSize: '16px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                letterSpacing: '1px',
                color: '#e34147',
                opacity: 0.8
            }
        },
        warn:{
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            color: '#e34146',
            textAlign:'center',
            marginTop: 15
        }
    })
);

interface BuilderPriceKitProps {
    data:{ 
        feature: {
            Image: string;
            Text: string;
        }[];
        price: string;
        warn?: string;
        tel?: string;
    }
}

export default function BuilderPriceKit(props: BuilderPriceKitProps) : JSX.Element{
    const classes = useStyles({});

    function FormRow(): JSX.Element[] {
        let ret: JSX.Element[] = [];
        for (let i = 0; i < props.data.feature.length; ++i) {
            const curData = props.data.feature[i];
            const TextLengh = 8 * curData.Text.replace(/[\u0391-\uFFE5]/g,"aa").length + 3 * 8;
            const marginL = -1 * (8 * curData.Text.replace(/[\u0391-\uFFE5]/g,"aa").length  - 68) / 2;
            ret.push(
                <Grid item xs key={curData.Text}> 
                    <div className={classes.Info} key={i}>
                        <img src={curData.Image} />
                        <p style={{marginLeft:marginL, paddingTop: 17, width:TextLengh}} >{curData.Text}</p>
                    </div>
                </Grid>
            );
        }
        return ret;
    }

    function GetSupport() : JSX.Element {
        const marginH : number = (1180 - props.data.feature.length * 116)/(props.data.feature.length * 2);
        return (
            <Grid container style={{ marginTop: 45, marginBottom:70, marginLeft:marginH * 2, marginRight:marginH, width: '100%' }} >
                <Grid container item key={props.data.price}>
                    {FormRow()}
                </Grid>
            </Grid>
        );
    }

    function GetBuilderPriceData() : JSX.Element[] {
        let ret : JSX.Element[] = [];
        ret.push(GetSupport());
        return ret;
    }
    return (
        <div className='Container'>
            {GetBuilderPriceData()}
            <ConnectButton text={props.data.price} tel={props.data.tel} classes={classes}/>
        </div>
    );
}