import * as React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1180,
    flexGrow: 1,
    // boxShadow: '0px 2px 20px 0px rgba(241, 241, 241, 0.9)'
  },
  img: {
    height: 318,
    display: 'block',
    maxWidth: 1180,
    overflow: 'hidden',
    width: '100%',
  },
  'swiper-pagination':{
    position: 'relative',
    marginTop:10,
    "& ul":{
        width: '100%',
        textAlign: 'center'
    },
    "& li":{
        listStyle: 'none',
        display:' inline-block',
        height: 10,
        width: 10,
        borderRadius: '50%',
        backgroundColor: 'rgb(204, 204, 204)',
        margin: '0 5px',
        cursor: 'pointer'
    },
    '& .selected':{
        backgroundColor: '#b7945d'
    }
}

}));

interface CaseImgProps {
  data: string[]
}

export default function CaseImgKit(props: CaseImgProps): JSX.Element {
  const classes = useStyles({});
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.data.length;

  function handleNext() {
    setActiveStep(prevActiveStep => (prevActiveStep + 1) % maxSteps
    );
  }

  function handleBack() {
    setActiveStep(prevActiveStep => (prevActiveStep + 3) % maxSteps);
  }

  function handleStepChange(step) {
    setActiveStep(step);
  }

  return (
    <div className={classes.root}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {props.data.map((step, index) => (
          <div key={step}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} src={step} onClick={e => window.location.hash = '#/Case'} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <div className={classes["swiper-pagination"]}>
        <ul>
          {
            props.data.map((element, index) => {
              return <li
              onClick={() => {handleStepChange(index)}}
               className={activeStep === index ? 'selected' : ''} 
              key={index}></li>
            })
          }
        </ul>
      </div>
      {/* <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        
        nextButton={
          <Button size="small" onClick={handleNext} style={{color: 'white'}}>
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} style={{color: 'white'}}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            
          </Button>
        }
        style={{marginTop:-192, backgroundColor: "transparent"}}
      /> */}
    </div>
  );
}