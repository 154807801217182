import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import '../../asset/components/Case/style/case.less'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            height: 460,
        },
        paper: {
            margin: '0px auto 0px',
            height: 460,
           flex:1,

           overflow: 'hidden',
           
            backgroundColor: "rgb(245, 245, 245)",
        },
        wapper_txt:{
            display: 'flex',
            justifyContent:'space-between'
        },
        imageR: {
            float: 'right',
            margin: '40px 20px',
        },
        imageL: {
            float: 'left',
            margin: '40px 20px',
        },
        interface:{
            marginTop:40,
            float:'left',
            fontSize: '16px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '0px',
            color: '#333333',
            "& dt":{
                marginBottom: 20
            }
        },
        infoR: {
            float: 'right',
            width:360,
            height: 342,
            margin: '60px 0',
            marginRight: 54
        },
        infoL: {
            float: 'left',
            width:360,
            height: 342,
            margin: '60px 0',
            marginLeft: 54
        },
        info: {
            '& h4':{
                fontSize: '22px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                letterSpacing: '0px',
                color: '#333333',
                textAlign: 'center',
                marginBottom: 20
            },
            '& p':{
                fontSize: '14px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                lineHeight: '22px',
                letterSpacing: '0px',
                color: '#666666'
            },
            '& img':{
                float: 'right',
                margin:'40px 0',
                marginRight:0
            }
        },
        ButtonL:{
            width:155,
            height:44,
            backgroundImage: 'linear-gradient(90deg, #b7945d 0%, rgba(183, 148, 93, 0.24) 50%, #b7945d 66%, #b7945d 100%), linear-gradient(#b7945d, #b7945d)',
            backgroundBlendMode: 'normal, normal',
            borderRadius: '2px',
            fontSize: '16px',
	        fontWeight: 'normal',
	        fontStretch: 'normal',
	        letterSpacing: '1px',
            color: '#ffffff',
            marginRight:40
        },
        ButtonR:{
            width:155,
            height:44,
            borderStyle: 'solid',
	        borderWidth: '1px',
	        borderColor: 'rgba(183, 148, 93, .5)',
            fontSize: '16px',
	        fontWeight: 'normal',
	        fontStretch: 'normal',
	        letterSpacing: '1px',
	        color: '#b7945d',
            '&:hover':{
                backgroundColor: 'rgba(183, 148, 93, 0.8)'
            },
            "& span": {
                fontSize: '16px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                letterSpacing: '1px',
                color: '#b7945d',
                opacity: 0.8,
                '&:hover':{
                    color: '#ffffff'
                },
            }
        },
       
    }),
);

interface ExampleKitItem{
    title: string,
    brief: string,
    typeIcon: string,
    image: string,
    interface: string[],
    bWebApp: boolean,
    url: string,
    downloadUrl: string
}

interface ExampleKitProps {
    data:ExampleKitItem[]
}

export default function ExampleKit(props: ExampleKitProps) : JSX.Element{
    const classes = useStyles({});

    function GetInterface(arrayData: string[]) : JSX.Element {
        let inf : JSX.Element[] = [];
        for(let i = 0; i < arrayData.length; ++i){
            const curData = arrayData[i];
            inf.push(<dt key={i}>{curData}</dt>)
        }
        return <dl className={classes.interface}>{inf}</dl>;
    }

    function GetExample(): JSX.Element[]
    {
        let ret : JSX.Element[] = [];
        let tmp  : JSX.Element[] = [];
        let whiteBG : boolean = true;
        for(let i = 0; i < props.data.length; ++i){
            const curData=props.data[i];
            let styleCon = 'HomeContainer_1';
            if(!whiteBG) styleCon = 'HomeContainer_1';
            ret.push(
                <div className={styleCon} key={i} >
                    <div className='Container'>
                        {i%2==0 && <div className="nums">{i+1}</div>}
                        <Grid container className={classes.root}  >
                            <Grid container justify="center" spacing={5} >
                                <Grid  item style={{width:'100%'}}>
                                    <div className={classes.paper}>
                                        <img src={curData.image} width='676px' height='380px' className={whiteBG ? classes.imageR : classes.imageL}/>
                                        <div className={whiteBG ? classes.infoL+" "+classes.info : classes.infoR+" "+classes.info}>
                                            <h4>{curData.title}</h4>
                                            <p>{curData.brief}</p>
                                            <div className={classes.wapper_txt}>
                                            {GetInterface(curData.interface)}
                                            <img src={curData.typeIcon} />
                                            </div>
                                            <Button className={classes.ButtonL} href={curData.url} target='_blank'>下载源码</Button>
                                            <Button className={classes.ButtonR} href={curData.downloadUrl} target='_blank'>{curData.bWebApp ? '在线体验' : '下载程序'}</Button>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        {i%2==1 && <div className="nums">{i+1}</div>}
                        
                    </div>
                </div>
            )
            whiteBG = !whiteBG;
        }
        return ret;
    }

    return (
        <React.Fragment>
            {GetExample()}
        </React.Fragment>

    );
}
