import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { AdapterLink } from '../AdapterLink/AdapterLink';

interface supportKitItem {
    Image: string,
    Text: string,
    To: string
}

interface supportKitProps {
    data: supportKitItem[],
    width?: number,
    height?: number,
    left?: number
}

export default function SupportKit(props: supportKitProps) {
    const useStyles = makeStyles(() =>
        createStyles({
            root: {
                flexGrow: 1,
            },
            paper: {
                margin: 'auto auto',
                height: 260,
                width: 366,
                marginTop: 0,
                backgroundColor:'#fff',
                boxShadow: '0px 2px 20px 0px rgba(241, 241, 241, 0.9)'
            },
            button: {
                width: props.width,
                height: props.height,
                marginTop: -88,
                marginLeft: props.left,
                textTransform: 'none',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'rgba(0, 0, 0, .5)',
                transition:'0.3s',
                borderRadius: '0',
                float: 'left',
                color: '#000',
                '&:hover':{
                    border: 'none',
                    backgroundColor: 'rgb(183, 148, 93)',
                    color:'#fff'
                },
                "& span": {
                    fontSize: '16px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    letterSpacing: '1px',
              
                }
            }
        }),
    );

    const classes = useStyles({});
    return (
        <Grid container className={classes.root}  >
            <Grid container justify="center" spacing={5} style={{marginTop: 0}}>
                {GetSupportKits()}
            </Grid>
        </Grid>
    );

    function GetSupportKits(): JSX.Element[] {
        let ret: JSX.Element[] = [];
        if (props && props.data) {
            for (let i = 0; i < props.data.length; i++) {
                const curData = props.data[i];
                ret.push(
                    <div className={classes.paper} key={curData.Text}>
                        <img width='100%' height='100%' src={curData.Image} />
                        <Button className={classes.button} component={AdapterLink} to={curData.To}>{curData.Text}</Button>
                    </div>
                );
            }
        }

        return ret;
    }
}
