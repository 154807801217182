import * as React from "react"
import MarkDown from '../Markdown/Markdown'
import Button from '@material-ui/core/Button'
import LBDP_ContentTitle from '../ContentTitle/ContentTitle'
import CharacterKit from './CharacterKit'
import SupportKit from './SupportKit'
import TagHistoryKit from '../TagHistory/TagHistoryKit'

import '../../asset/components/Product/style/product.less'
import '../../asset/declare'

const globalData = require('config').globalConfig;
const productConfig = require('productConfig');
const productData = require('productData');

export default class Motor_SDK extends React.Component {
    constructor(props: any) {
        super(props);
    }
    public render(): JSX.Element {
        return (
            <div className='ComponentStyle'>
                <div className='Banner ProductBanner'>
                    {/* <div className='MotorContainer'>
                        <MarkDown className='Info'>{productData.brief.Motor}</MarkDown>
                        <Button className="LinkButton" href={globalData.GitHub.GitHubAll} target="_blank" style={{ marginLeft: -562 }}>立即下载</Button>
                        <Button className="LinkButton" href={globalData.Motor.CodeBox} target="_blank" style={{ marginLeft: 40 }}>在线体验</Button>
                    </div> */}
                </div>

                {/* <div className='Charact'>
                    <div className='TitleContainer'>
                        <LBDP_ContentTitle text='5大特性' english='Five Characteristics' />
                    </div>
                    <div className='HomeContainer'>
                        <div className='BackGround'></div>
                        <div className='Container'>
                         
                            <CharacterKit data={productConfig.CharaterConfig.Motor} />
                        </div>
                    </div>
                </div> */}

                <div className='Support'>
                    <div className='TitleContainer'>
                        <LBDP_ContentTitle text='开发支持' english='Development Support' />
                    </div>
                    <div className='HomeContainer'>
                        <div className='Container'>
                            <SupportKit data={productConfig.SupportConfig.Motor} width={114} height={38} left={126} />
                        </div>
                    </div>
                </div>
                <div className='TagHistory'>
                    <div className='TitleContainer'>
                        <LBDP_ContentTitle text='产品动态' english='Products News' />
                    </div>
                    <div className='Container'>
                        <TagHistoryKit data={productData.historyTagData}/>
                    </div>
                </div>
            </div>
        );
    }
}
