import * as React from 'react'
import { makeStyles, createStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import ConnectButton from './connectButton'
import { NoEncryption } from '@material-ui/icons';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '0 auto',
            width: 1180,
            height: 260,
            backgroundColor: 'transparent'
        },
        paper: {
            margin: 'auto auto',
            height: 245,
            width: 265,
            backgroundColor: '#ffffff',
            boxShadow: '0px 2px 20px 0px rgba(241, 241, 241, 0.9)'
        },
        Info: {
            fontSize: '18px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '0px',
            color: '#333333',
            textAlign: 'center',
            marginTop: -70
        },
        buttonDiv : {
            width: 200,
            height: 44,
            margin:'0 auto',
            marginTop: 70
        },
        button :{
            width: 200,
            height: 44,
            backgroundImage: 'linear-gradient(90deg, #ff2a54 0%, rgba(241, 54, 77, 0.24) 50%, #ed3a4b 66%, #e34146 100%), linear-gradient(#e34147, #e34147)',
            backgroundBlendMode: 'normal, normal',
            borderRadius: '2px',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 'normal',
            letterSpacing: '0px',
            color: '#ffffff',
        },
        buttonTel:{
            width:200,
            height:44,
            borderStyle: 'solid',
	        borderWidth: '1px',
	        borderColor: 'rgba(227, 65, 71, .5)',
            fontSize: '16px',
	        fontWeight: 'normal',
	        fontStretch: 'normal',
	        letterSpacing: '1px',
	        color: '#e34147',
	        '&:hover':{
                backgroundColor: 'rgba(227, 65, 71, 0.2)'
            },
            "& span": {
                fontSize: '16px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                letterSpacing: '1px',
                color: '#e34147',
                opacity: 0.8
            }
        }
    })
);

interface ServiceKitProps {
    data:{
        text: string;
        tel: string;
        services: {
            Image: string;
            Text: string;
        }[];
    }
}

export default function ServiceKit(props: ServiceKitProps) : JSX.Element{
    const classes = useStyles({});
    let [bShowTel , setShowTel] = React.useState(false);

    function GetServiceData() :JSX.Element[] {
        let ret: JSX.Element[] = [];
        if (props && props.data) {
            for (let i = 0; i < props.data.services.length; i++) {
                const curData = props.data.services[i];
                ret.push(
                    <div className={classes.paper} key={curData.Text}>
                        <img width='100%' height='100%' src={curData.Image} />
                        <p className={classes.Info} >{curData.Text}</p>
                    </div>
                );
            }
        }

        return ret;
    }

    return (
        <React.Fragment>
            <Grid container className={classes.root}  >
                <Grid container justify="center" spacing={5} style={{marginTop: 0}}>
                    {GetServiceData()}
                </Grid>
            </Grid>
            <ConnectButton text={props.data.text} tel={props.data.tel} classes={classes}/>
        </React.Fragment>
    )
}
