import * as React from "react";
import MarkDown from '../Markdown/Markdown'
import Button from '@material-ui/core/Button'
import LBDP_ContentTitle from '../ContentTitle/ContentTitle'
import CharacterKit from './CharacterKit'
import SupportKit from './SupportKit'

import '../../asset/components/Product/style/product.less'
import '../../asset/declare'

const globalData = require('config').globalConfig;
const productConfig = require('productConfig');
const productData = require('productData');

export default class Build_SDK extends React.Component {
    constructor(props: any) {
        super(props);
    }
    public render(): JSX.Element {
        return (
            <div className='ComponentStyle'>
                <div className='Banner ProductBanner'>
                    <div className='BuilderContainer'>
                        <MarkDown className='Info'>{productData.brief.Builder}</MarkDown>
                        <Button className="LinkButton" href={globalData.GitHub.GitHubAll} target="_blank">立即下载</Button>
                    </div>
                </div>
                <div className='Charact'>
                    <div className='TitleContainer'>
                        <LBDP_ContentTitle text={'3大特性'} english='Three Characteristics' />
                    </div>
                    <div className='HomeContainer'>
                        <div className='BackGround'></div>
                        <div className='Container'>
                            <CharacterKit data={productConfig.CharaterConfig.Builder} />
                        </div>
                    </div>
                </div>
                <div className='Support'>
                    <div className='TitleContainer'>
                        <LBDP_ContentTitle text='开发支持' english='Development Support' />
                    </div>
                    <div className='HomeContainer'>
                        <div className='Container' style={{height: 400}}>
                            <SupportKit data={productConfig.SupportConfig.Builder}  width={160} height={38} left={103}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
