import * as React from 'react'
import { makeStyles, createStyles} from '@material-ui/core/styles';
import ConnectButton from './connectButton'
import '../../asset/declare'
import featureIcon from '../../asset/components/Price/res/price_ic_pickOn.png'
import '../../asset/components/Price/style/Price.less'

const useStyles = makeStyles(() =>
    createStyles({
        priceBox: {
            width: 366,
            height: 704,
            backgroundColor: '#ffffff',
            boxShadow: '0px 2px 20px 0px rgba(241, 241, 241, 0.9)',
            float: 'left',
            marginLeft: 20,
            marginRight: 20,
        },
        banner:{
            width: 367,
            height: 90,
            marginBottom: 30,
        },
        title: {
            fontSize: '24px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '0px',
            color: '#ffffff',
            marginTop:-95,
            marginBottom: 74,
            marginLeft: 30,
        },
        featureBox: {
            height: 468,
            width: '100%'
        },
        feature: {
            fontSize: '16px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '0px',
            color: '#333333',
            marginBottom: 20,
            marginLeft: 30,
            '& img':{
                marginRight: 15,
            }
        },
        buttonLabel :{
            width: 260,
            height: 44,
            backgroundImage: 'linear-gradient(90deg, #ff2a54 0%, rgba(241, 54, 77, 0.24) 50%, #ed3a4b 66%, #e34146 100%), linear-gradient(#e34147, #e34147)',
            backgroundBlendMode: 'normal, normal',
            borderRadius: '2px',
            textAlign: 'center',
            marginLeft: 54,
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '1px',
            color: '#ffffff'
        },
        button :{
            width: 260,
            height: 44,
            backgroundImage: 'linear-gradient(90deg, #ff2a54 0%, rgba(241, 54, 77, 0.24) 50%, #ed3a4b 66%, #e34146 100%), linear-gradient(#e34147, #e34147)',
            backgroundBlendMode: 'normal, normal',
            borderRadius: '2px',
            fontSize: '20px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '1px',
            color: '#ffffff',
            "& span":{
                marginTop:-2
            }
        },
        buttonDiv : {
            width: 260,
            height: 44,
            marginLeft: 54,
        },
        buttonTel:{
            width:260,
            height:44,
            borderStyle: 'solid',
	        borderWidth: '1px',
	        borderColor: 'rgba(227, 65, 71, .5)',
	        fontWeight: 'normal',
	        fontStretch: 'normal',
	        letterSpacing: '1px',
	        color: '#e34147',
	        '&:hover':{
                backgroundColor: 'rgba(227, 65, 71, 0.2)'
            },
            "& span": {
                fontSize: '20px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                letterSpacing: '1px',
                color: '#e34147',
                opacity: 0.8,
                marginTop:-2
            }
        },
        price: {
            fontSize: '22px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '1px',
            color: '#ffffff'
        },
        unit: {
            fontSize: '16px',
            fontWeight: 'normal',
            letterSpacing: '1px',
            color: '#ffffff',
            paddingTop: 7
        },
        warn:{
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            color: '#e34146',
            textAlign:'center',
            marginTop: -29,
            marginBottom: 10
        }
    })
);

interface MotorPriceKitProps {
    data: {
        titleImage: string;
        title: string;
        feature: string[];
        price: string;
        unit: string;
        warn?: string;
        tel?:string;
    }[]
}

export default function MotorPriceKit(props : MotorPriceKitProps) : JSX.Element{
    const classes = useStyles({});

    function GetFeature(feature: string[]) : JSX.Element[] {
        let ret : JSX.Element[] = [];
        for(let i = 0; i < feature.length; ++i) {
            ret.push(<p className={classes.feature} key={i}>
                <img src={featureIcon} width={18} height={18}/>
                    {feature[i]}
                </p>);
        }
        return ret;
    }

    function GetPriceData() : JSX.Element[]{
        let ret : JSX.Element[] = [];
        for(let i = 0; i < props.data.length; ++i) {
            const curData = props.data[i];
            ret.push(
                <div className={classes.priceBox} key={curData.title}>
                    <img src={curData.titleImage} className={classes.banner} />
                    <p className={classes.title}>{curData.title}</p>
                    <div className={classes.featureBox}>
                        {GetFeature(curData.feature)}
                    </div>
                    {curData.warn ? <p className={classes.warn}>{curData.warn}</p>: null}
                    {curData.tel ? <ConnectButton text={curData.price} tel={curData.tel} classes={classes}/>
                    : <div className={classes.buttonLabel}>
                        <p className={classes.unit}>
                            <span className={classes.price}>{curData.price}</span>
                            {curData.unit}
                        </p>
                    </div>
                }  
                </div>
            )
        }
        return ret;
    }

    return (
        <div className='Container'>
            {GetPriceData()}
        </div>
    );
}