import * as React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import '../../asset/components/Home/styles/home.less'
import '../../asset/declare'
import userIcon from '../../asset/components/Home/res/logIn_ic_user.png'
import pwdIcon from '../../asset/components/Home/res/logIn_ic_key.png'
import uncheckIcon from '../../asset/components/Home/res/logIn_ic_danxuan.png'
import checkedIcon from '../../asset/components/Home/res/logIn_ic_xuanzhong.png'
import userHeader from '../../asset/components/Home/res/header_pic_default_02.png'

import md5 from 'js-md5'
import CryptoJS from 'crypto-js'

const AES_CBC_ENCRYPT = (text: string) => {
    const secretHex = CryptoJS.enc.Utf8.parse('0123456789ABHAEQ')
    const keyHex = CryptoJS.enc.Utf8.parse('DYgjCEIMVrj2W9xN')
    const ivHex = keyHex.clone()
    const messageHex = CryptoJS.enc.Utf8.parse(text)
    const encrypted = CryptoJS.AES.encrypt(messageHex, secretHex, {
      iv: ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })
    return encrypted.ciphertext.toString()
  }
  const AES_CBC_DECODE = (word: string) => {
    //如果加密返回的base64Str
    var srcs = word
    const secretHex = CryptoJS.enc.Utf8.parse('0123456789ABHAEQ')
    const keyHex = CryptoJS.enc.Utf8.parse('DYgjCEIMVrj2W9xN')
    //若上面加密返回的hexStr,需打开下面两行注释，再次处理
    var encryptedHexStr = CryptoJS.enc.Hex.parse(word)
    var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
    var decrypt = CryptoJS.AES.decrypt(srcs, secretHex, {
      iv: keyHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })
    var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    var value = decryptedStr.toString()
    return value
  }


const classes = createStyles(
    {
        form: {
            width: 300,
            height: 211,
            marginTop: 35,
            marginLeft: 25
        },
        usrBox: {
            width: 300,
            height: 44
        },
        pwdBox: {
            width: 300,
            height: 44,
            marginTop: 20,
        },
        input: {
            width: 258,
            height: 44,
            color: 'white',
            backgroundColor: 'transparent',
            borderRadius: '2px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            border: 'solid 1px rgb(65,71,88)',
            borderLeft: 'none',
            textIndent: 5,
            WebkitTextFillColor: 'white',
            WebkitBoxShadow: '0 0 0px 1000px #293146 inset',

        },
        iconImg: {
            float: 'left',
            width: 40,
            height: 44,
            border: 'solid 1px rgba(255,255,255,0.1)',
            borderRadius: '2px',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: 'none',
        },
        icon: {
            marginLeft: 12,
            marginTop: 13
        },
        split: {
            float: 'right',
            width: 1,
            height: 16,
            marginTop: 13,
            borderRight: 'solid 1px rgba(255,255,255,0.1)'
        },
        checkArea: {
            width: '100%',
            height: 14,
            marginTop: 30
        },
        remember: {
            width: 286,
            fontSize: '12px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '1px',
            color: '#ffffff',
            opacity: 0.8,
            marginTop: 1,
            textIndent: 10,
            float: 'right'
        },
        submit: {
            float: 'left',
            width: 130,
            height: 44,
            marginTop: 16,
            backgroundImage: 'linear-gradient(90deg, #ff2a54 0%, rgba(241, 54, 77, 0.24) 50%, #ed3a4b 66%, #e34146 100%), linear-gradient(#e34147, #e34147)',
            backgroundBlendMode: 'normal, normal',
            borderRadius: '2px',
            fontSize: '16px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '1px',
            color: '#ffffff'
        },
        regist: {
            float: 'right',
            width: 130,
            height: 44,
            marginTop: 16,
            borderStyle: 'solid',
            borderWidth: '1px',
            borderImageSource: 'linear-gradient(90deg, #ff2a54 0%, #e34146 100%)',
            borderImageSlice: 1,
            color: '#e34147',
            fontSize: '16px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '1px',
            opacity: 0.5,
            backgroundColor: 'transparent'
        },
        usrInfoForm: {
            width: 290,
            height: 211,
            marginTop: 25,
            marginLeft: 30,
            color: 'white',
        },
        usrInfo: {
            width: '100%',
            height: '60px',
            lineHeight: '25px',
            textIndent: 10
        },
        dashBoard: {
            width: '100%',
            height: 44,
            marginTop: 30,
            backgroundImage: 'linear-gradient(90deg, #ff2a54 0%, rgba(241, 54, 77, 0.24) 50%, #ed3a4b 66%, #e34146 100%), linear-gradient(#e34147, #e34147)',
            backgroundBlendMode: 'normal, normal',
            borderRadius: '2px',
            fontSize: '16px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '1px',
            color: '#ffffff'
        },
        warn:{
            float:'left',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            color: '#e34146',
            marginTop: 5
        },
    });

interface LoginKitStates {
    bLogin: boolean;
    bRemember: boolean;
}

const globalData = require('config').globalConfig;

export default class LoginKit extends React.Component<any, LoginKitStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            bLogin: false,
            bRemember: false
        }
        this.token = null;
        this.onCheckRemember = this.onCheckRemember.bind(this);
        this.Login = this.Login.bind(this);
        this.autoCompeletePWD = this.autoCompeletePWD.bind(this);
    }

    componentWillMount() {
        let self = this;
        let arr,reg=new RegExp("(^| )"+'ldbp_curToken'+"=([^;]*)(;|$)");
        if(arr=document.cookie.match(reg)){
            self.token =  decodeURI(arr[2]);
        }
        else{
            self.token = null;
        }
        
        const func = ()=>{
            self.setState({
                bLogin: false
            });
            self.curTokenChange('');
            window.location.reload();
        }
        
        if(self.token != null && self.token != 'null' && self.token != ''){
            axios.get('/uc/user/detail',
            {
                baseURL:globalData.serverUrlV3,
                responseType: 'json',
                headers: {
                    'access-token': self.token
                }

            }).then(function (response) {
                if(response.data.code == 200){
                    self.userName = response.data.data.loginName;
                    self.setState({
                        bLogin: true
                    });
                }
                else{
                    func();
                }
            })
            .catch(function (error) {
                //alert(error);
                func();
            })
        }
        else{
            self.curTokenChange('');
        }
    }

    public render(): JSX.Element {
        return (
            <div className='LoginContainer'>
                {
                    this.state.bLogin ?
                        <div style={classes.usrInfoForm}>
                            {this.GetUsrInfo()}
                        </div>
                        :
                        <div style={classes.form}>
                            {this.GetLogin()}
                        </div>
                }
            </div>
        );
    }

    private onCheckRemember(): void {
        this.setState({
            bRemember: !this.state.bRemember
        });
    }

    private GetLogin(): JSX.Element {
        let ret: JSX.Element = (
            <React.Fragment>
                <div style={classes.usrBox}>
                    <input type="text | email" placeholder="用户名" name="username" id="username" autoComplete='email | text' style={classes.input} ref={val => this.uid = val} required onInput={this.autoCompeletePWD}/>
                    <div style={classes.iconImg}><img src={userIcon} width={18} height={18} style={classes.icon} /><div style={classes.split}></div></div>
                </div>
                <div style={classes.pwdBox} >
                    <input type="password" placeholder="密　码" name="password" id="password" autoComplete='current-password' style={classes.input} ref={val => this.pwd = val} required onInput={(e)=>{this.setState({bLogin: false,bRemember: false});this.errInfo=""}}/>
                    <div style={classes.iconImg}><img src={pwdIcon} width={18} height={18} style={classes.icon} /><div style={classes.split}></div></div>
                </div>
                <p style={classes.warn}>{this.errInfo}</p>
                <div style={classes.checkArea} >
                    <img
                        src={this.state.bRemember ? checkedIcon : uncheckIcon}
                        onClick={this.onCheckRemember}
                    />
                    <p style={classes.remember}>记住密码</p>
                </div>
                <Button
                    variant="contained"
                    style={classes.submit}
                    onClick={this.Login}
                >
                    登录
                </Button>
                <Button
                    variant="contained"
                    style={classes.regist}
                    href={globalData.registerV3} 
                    target='_blank'
                >
                    注册
                </Button>
            </React.Fragment>
        );
        return ret;
    }

    private GetUsrInfo(): JSX.Element {
        let curDev: number = 0;
        let curProjNum: number = 0;
        const usrIcon: string = userHeader;
        const self = this;
        axios.get('/bp/proj/info',
        {
            baseURL:globalData.serverUrlV3,
            responseType: 'json',
            headers: {
                'access-token': self.token
            }

        }).then(function (response) {
            if(response.data.code == 200){
                curDev = response.data.data.length;
                for(let i = 0; i < curDev; ++i){
                    const curData = response.data.data[i];
                    curProjNum += curData.cimCount + curData.bimCount;
                }
                self.curDev.innerText = String(curDev);
                self.curProjNum.innerText = String(curProjNum);
            }
            else{
                alert(response.data.msg);
                self.curTokenChange('');
                self.setState({
                    bLogin: false,
                    bRemember: false
                });
                window.location.reload();
            }
        })
        .catch(function (error) {
            alert(error);
        })

        return (
            <React.Fragment>
                <div style={classes.usrInfo}>
                    <img src={usrIcon} width={60} height={60} style={{ float: 'left' }} />
                    <p style={{ fontSize: 18, paddingTop: 10 }}>{this.userName}</p>
                    <p style={{ fontSize: 12, opacity: 0.8 }}>亲爱的开发者欢迎回来</p>
                </div>
                <p style={{ fontSize: 14, marginTop: 34, opacity: 0.9 }}>
                    当前共有
                    <span style={{ fontSize: 16, opacity: 0.9 }} ref={val => this.curDev = val}>{0}</span>
                    个应用正在开发
                </p>
                <p style={{ fontSize: 14, marginTop: 15, opacity: 0.9 }}>
                    已上传
                    <span style={{ fontSize: 16, opacity: 0.9 }} ref={val => this.curProjNum = val}>{0}</span>
                    个工程数据
                </p>
                <Button style={classes.dashBoard} tabIndex={-1} href={globalData.dashboardV3} target='_blank'>进入控制台</Button>
            </React.Fragment>
        );
    }
    
    private Login() : void{
        const self = this;
        const uid = self.uid.value;
        // AES_CBC_ENCRYPT()
        const pwd = AES_CBC_ENCRYPT(self.pwd.value);
        if(uid.length == 0 || pwd.length == 0){
            self.errInfo='用户名或密码不能为空';
            self.setState({
                bLogin: false,
                bRemember: false
            });
            return;
        }
        console.log(uid,pwd)
        axios.post('/uc/auth/login/dev',
        {
             username: uid, 
             password:pwd
        },
        {
            baseURL:globalData.serverUrlV3,
            // baseURL:"https://open.lubansoft.com/api/motor/v2",
            responseType: 'json'

        }).then(function (response) {
            if(response.data.code !== 200){
                self.errInfo=response.data.msg;
                let accountInfo = self.removeCache(uid);
                let Days = 365;  //cookie保存时间
                let exp = new Date();
                exp.setTime(exp.getTime() + Days*24*60*60*1000);
                document.cookie = 'ldbp_AccountInfo' + "="+ encodeURI(accountInfo) + ";expires=" + exp.toUTCString();
                self.curTokenChange('');
                self.setState({
                    bLogin: false,
                    bRemember: false
                });
            }
            else {
                
                self.curTokenChange(response.data.data["accessToken"])
                let accountInfo : string = "[]";
                if(self.state.bRemember){
                    accountInfo = self.addCache(uid, pwd);
                }
                else {
                    accountInfo = self.removeCache(uid);
                }

                // console.log(777, accountInfo)
                let Days = 365;  //cookie保存时间
                let exp = new Date();
                exp.setTime(exp.getTime() + Days*24*60*60*1000);
                document.cookie = 'ldbp_AccountInfo' + "="+ encodeURI(accountInfo) + ";expires=" + exp.toUTCString();
                window.location.reload();
            }
        })
        .catch(function (error) {
            //alert(error);
            let accountInfo = self.removeCache(uid);
            let Days = 365;  //cookie保存时间
            let exp = new Date();
            exp.setTime(exp.getTime() + Days*24*60*60*1000);
            document.cookie = 'ldbp_AccountInfo' + "="+ encodeURI(accountInfo) + ";expires=" + exp.toUTCString();
        })
    }

    private curTokenChange(token: string) : void {
        let Days = 365;  //cookie保存时间
        let exp = new Date();
        exp.setTime(exp.getTime() + Days*24*60*60*1000);
        document.cookie = 'ldbp_curToken' + "="+ encodeURI(token) + ";expires=" + exp.toUTCString();
        this.token = token;
    }

    private addCache(uid: string, pwd: string) : string {
        let arr,reg=new RegExp("(^| )"+'ldbp_AccountInfo'+"=([^;]*)(;|$)");
        let accountInfo ='';
        if(arr=document.cookie.match(reg)){
            accountInfo =  decodeURI(arr[2]);
        }
        else{
            accountInfo = '[]';
        }
        let cache = JSON.parse(accountInfo);
        let bFind = false;
        for(let i = 0; i < cache.length; ++i){
            const curCache = cache[i];
            if(curCache.username == uid) {
                bFind = true;
                break;
            }
        }
        if(!bFind){
            cache.push({username: uid, password: pwd});
        }
        return JSON.stringify(cache);
    }

    private removeCache(uid: string) : string {
        let arr,reg=new RegExp("(^| )"+'ldbp_AccountInfo'+"=([^;]*)(;|$)");
        let accountInfo ='';
        if(arr=document.cookie.match(reg)){
            accountInfo =  decodeURI(arr[2]);
        }
        else{
            accountInfo = '[]';
        }
        let cache = JSON.parse(accountInfo);
        cache = cache.filter(function(item) {
            return item.username != uid
        });
        return JSON.stringify(cache);
    }

    private autoCompeletePWD(event: React.FormEvent<HTMLInputElement>) : void {
        this.errInfo="";
        let arr,reg=new RegExp("(^| )"+'ldbp_AccountInfo'+"=([^;]*)(;|$)");
        let accountInfo =''
        if(arr=document.cookie.match(reg)){
            accountInfo =  decodeURI(arr[2]);
        }
        else{
            accountInfo = null;
        }
    
        if(Boolean(accountInfo) != false){
            let cache = JSON.parse(accountInfo);
            this.pwd.value = "";
            this.setState({
                bLogin: false,
                bRemember: false
            });
            for(let i = 0; i < cache.length; ++i){
                const curData = cache[i];
                if(curData.username == this.uid.value) {
                    this.pwd.value = curData.password;
                    this.setState({
                        bLogin: false,
                        bRemember: true
                    });
                    break;
                }
            }
        }
    }

    private uid: HTMLInputElement;
    private pwd: HTMLInputElement;
    private token: string;
    private userName: string;
    private curDev: HTMLSpanElement;
    private curProjNum: HTMLSpanElement;
    private errInfo: string;
}