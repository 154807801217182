import * as React from 'react';
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import MobileStepper from "@material-ui/core/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import Fab from '@material-ui/core/Fab';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MarkDown from '../Markdown/Markdown';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import '../../asset/declare';
// 需要换图
import DotImage from "../../asset/components/TagHistory/res/dot.png";

interface historyTagStep {
  date: string,
  title: string,
  brief: string,
  detail: string
}

interface historyTagSteps {
  data: historyTagStep[]
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: '100%',
  },
  swipeable: {
    paddingTop: 0,
    marginTop: 204
  },
  labelTag: {
    paddingTop: 0,
    marginTop: -114
  },
  date: {
    fontSize: 24,
    fontWeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0,
    color: '#333333'
  },
  historyTag: {
    marginTop: -292,
    width: "100%",
    height: '100%',
    '& .react-swipeable-view-container': {
      height: '100%'
    }
  },
  Down: {
    marginTop: 95
  },
  TagDown: {
    paddingTop: 250
  },
  paper: {
    position: 'absolute',
    height: 'auto',
    minHeight: 78,
    width: 194,
    boxShadow: '0px 2px 20px 0px rgba(241, 241, 241, 0.9)',
    textAlign: 'left',
    backgroundColor: 'white',
    cursor: 'pointer',
    '& h4': {
      marginBottom: 0,
      height: 20,
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      letterSpacing: 0,
      color: '#666666'
    },
    '&:hover': {
      boxShadow: '0px 3px 5px -1px rgba(111, 111, 111, 0.2), 0px 6px 10px 0px rgba(111, 111, 111,0.14), 0px 1px 18px 0px rgba(111, 111, 111,0.12)'
    }
  },
  paperTitlePt: {
    width: 8,
    height: 8,
    marginLeft: 20,
    marginTop: 22,
    float: 'left',
    backgroundColor: '#b7945d'
  },
  paperTitle: {
    paddingTop: 16,
    paddingLeft: 33,
    paddingBottom: 4,
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0px',
    color: '#333333'
  },
  paperBrief: {
    paddingLeft: 20
  },
  link: {
    padding: '11px 20px',
    paddingBottom: 16,
    fontSize: 14,
    letterSpacing: 0,
    color: '#b7945d'
  },
  triangle: {
    height: 7,
    width: 11
  },
  mobileStep: {
    marginTop: -362
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    width: 600,
    height:800,
    overflowX: 'hidden',
    overflowY:'auto'
  },
  markdownModal:{
    width:600, 
    height:800,
    '& p,li':{
        fontSize: '14px',
        letterSpacing: '1px',
        color: '#000'
    }
}
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    width: 36,
    height: 36
  }
});

const LBDP_HistorySteper = withStyles({
  horizontal: {
    padding: 0
  }
})(Stepper);

const LBDP_HistoryStep = withStyles({
  horizontal: {
    padding: 0
  }
})(Step);

const LBDP_MobileSteper = withStyles({
  root: {
    padding: 0,
    backgroundColor: '#fff'
  }
})(MobileStepper)

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 15,
    left: 'calc(-100%)',
    right: 'calc(0%)'
  },
  line: {
    marginTop: 2,
    height: 2,
    border: 'none',
    backgroundColor: "#333333"
  }
})(StepConnector);

const LBDP_TagConnector = withStyles({
  alternativeLabel: {
    top: 15
  },
  line: {
    marginTop: 2,
    height: 2,
    border: 0,
    display: 'none'
  }
})(StepConnector);

const LBDP_FAB = withStyles({
  root: {
    boxShadow: 'none',
    '&.Mui-disabled': {
      backgroundColor: 'rgb(240, 234, 224)'
    }
  },
  primary: {
    backgroundColor: 'rgb(240, 234, 224)',
    '&:hover': {
      backgroundColor: 'rgb(240, 234, 224)',
      boxShadow: '0px 3px 5px -1px rgba(111, 111, 111, 0.2), 0px 6px 10px 0px rgba(111, 111, 111,0.14), 0px 1px 18px 0px rgba(111, 111, 111,0.12)'
    }
  },
  sizeSmall: {
    width: 36,
    height: 36
  }
})(Fab);

const LBDP_ArrowLeft = withStyles({
  root: {
    color: '#000',
    width: 32,
    height: 32
  },
  colorPrimary: {
    opacity: 0.8
  },
  colorDisabled: {
    opacity: 0.4
  }
})(KeyboardArrowLeftIcon);

const LBDP_ArrowRight = withStyles({
  root: {
    color: '#000',
    width: 32,
    height: 32,
    
  },
  colorPrimary: {
    opacity: 0.8
  },
  colorDisabled: {
    opacity: 0.4
  }
})(KeyboardArrowRightIcon)

function ColorlibStepIcon(): JSX.Element {
  const classes = useColorlibStepIconStyles({});
  return (
    <div className={classes.root}>
      <img src={DotImage} alt='tag' />
    </div>
  );
}

function GetTagHistory(bUp: boolean, bFull:boolean, step: historyTagStep): JSX.Element {
  const classes = useStyles({});
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styleObjUp =  bFull ? {position: 'absolute', bottom: -172, left:'calc(18%)'} : {position: 'absolute', bottom: -172, left:'calc(25%)'};
  const styleObjDown = bFull ? {left:'calc(18%)'} : {left:'calc(25%)'};

  return (
    <React.Fragment>
      <div className={classes.paper} style={bUp ? styleObjUp : styleObjDown} onClick={handleOpen}>
        <div className={classes.paperTitlePt} />
        <p className={classes.paperTitle} >{step.title}</p>
        <MarkDown className={classes.paperBrief}>{step.brief}</MarkDown>
        <p className={classes.link}>查看详情</p>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.modalPaper}>
            <MarkDown className={classes.markdownModal}>{step.detail}</MarkDown>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>

  );
}

function GetTagStep(steps: historyTagStep[]): JSX.Element[] {
  const classes = useStyles({});
  let ret: JSX.Element[] = [];
  let stepArry: JSX.Element[] = [];

  let funcStep = (i: number,paramArry:any) => {
    ret.push(
      <LBDP_HistorySteper
        alternativeLabel
        activeStep={steps.length}
        connector={<LBDP_TagConnector />}
        style={{ backgroundColor: 'transparent'}}
        key={i}
      >
        {paramArry}
      </LBDP_HistorySteper>
    );
  }
  for (let i = 0; i < steps.length; ++i) {
    let step = steps[i];
    stepArry.push(
      <Step key={step.date} >
        <StepLabel StepIconComponent={() => <></>} className={i % 2 === 0 ? null : classes.TagDown} >{GetTagHistory(i % 2 === 0, steps.length % 4 === 0, step)}</StepLabel>
      </Step>

    );

    if (i % 4 == 3 && i + 1 !== steps.length) {
      funcStep(i,[...stepArry]); 
    }
    if(i > 3){
      stepArry.splice(0,1);
    }
  }
  funcStep(steps.length + 1,[...stepArry]);
  return ret;
}

function GetDateStep(steps: historyTagStep[]): JSX.Element[] {
  const classes = useStyles({});
  let ret: JSX.Element[] = [];
  let stepArry: JSX.Element[] = [];

  let funcStep = (i: number,paramArry:any) => {
    ret.push(
      <LBDP_HistorySteper
        alternativeLabel
        activeStep={steps.length}
        connector={<LBDP_TagConnector />}
        style={{ backgroundColor: 'transparent' }}
        key={i}
      >
        {paramArry}
      </LBDP_HistorySteper>
    );
  }
  for (let i = 0; i < steps.length; ++i) {
    let step = steps[i];
    stepArry.push(
      <LBDP_HistoryStep key={step.date}>
        <StepLabel StepIconComponent={() => <></>} className={i % 2 === 0 ? classes.Down : null}><span className={classes.date}>{step.date}</span></StepLabel>
      </LBDP_HistoryStep>

    );
    if (i % 4 == 3 && i + 1 !== steps.length) {
      funcStep(i,[...stepArry]);
    }
    if(i > 3){
      stepArry.splice(0,1);
    }
  }
  funcStep(steps.length + 1,[...stepArry]);
  return ret;
}

function GetSteper(steps: historyTagStep[]): JSX.Element[] {
  let ret: JSX.Element[] = [];
  let stepArry: JSX.Element[] = [];
  let funcStep = (i: number, paramArry:any) => {
    ret.push(
      <LBDP_HistorySteper
        alternativeLabel
        activeStep={steps.length}
        connector={<ColorlibConnector />}
        style={{ backgroundColor: 'transparent' }}
        key={i}
      >
        {paramArry}
      </LBDP_HistorySteper>
    );
    //stepArry = [];
  }
  for (let i = 0; i < steps.length; ++i) {
    let step = steps[i];
    stepArry.push(
      <LBDP_HistoryStep key={step.date} >
        <StepLabel StepIconComponent={ColorlibStepIcon}> </StepLabel>
      </LBDP_HistoryStep>

    );
    
    if (i % 4 == 3 && i + 1 !== steps.length) {
      //let stepArryTemp = [...stepArry];
      funcStep(i, [...stepArry]);
    }
    if(i>3){
      stepArry.splice(0,1);
    }
  }
  funcStep(steps.length + 1,[...stepArry]);
  return ret;
}

export default function TagHistoryKit(props: historyTagSteps): JSX.Element {
  const classes = useStyles({});
  const steps = props.data;
  const theme = useTheme();
  const maxSteps = Math.ceil(steps.length / 4);
  const [activeStep, setActiveStep] = React.useState(maxSteps - 1);

  if (steps === null) { return null; }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        className={classes.swipeable}
      >
        {GetSteper(steps)}
      </SwipeableViews>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        className={classes.labelTag}
      >
        {GetDateStep(steps)}
      </SwipeableViews>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        className={classes.historyTag}
      >
        {GetTagStep(steps)}
      </SwipeableViews>
      <LBDP_MobileSteper
        steps={maxSteps}
        position="static"
        variant={null}
        activeStep={activeStep}
        className={classes.mobileStep}
        nextButton={
          <LBDP_FAB size="small" color="primary" onClick={handleNext} disabled={activeStep === maxSteps - 1} >
            {
              theme.direction === 'rtl' ?
                <LBDP_ArrowLeft color={activeStep === maxSteps - 1 ? "disabled" : "primary"} />
                :
                <LBDP_ArrowRight color={activeStep === maxSteps - 1 ? "disabled" : "primary"} />
            }
          </LBDP_FAB>
        }
        backButton={
          <LBDP_FAB size="small" color="primary" onClick={handleBack} disabled={activeStep === 0}>
            {
              theme.direction === 'rtl' ?
                <LBDP_ArrowRight color={activeStep === 0 ? "disabled" : "primary"} />
                :
                <LBDP_ArrowLeft color={activeStep === 0 ? "disabled" : "primary"} />
            }
          </LBDP_FAB>
        }
      />
    </div>
  );
}
