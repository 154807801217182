import * as React from "react";
import MarkDown from '../Markdown/Markdown'
import LBDP_ContentTitle from '../ContentTitle/ContentTitle';
import ExampleKit from './ExampleKit';
import '../../asset/components/Case/style/case.less'


const ExampleConfig = require("exampleConfig");

export default class Example extends React.Component {
    constructor(props: any) {
        super(props);
    }
    
    public render(): JSX.Element {
        return (
            <div className='ComponentStyle' style={ExampleConfig.ExampleData.length % 2 == 0 ? {} : {marginBottom:100}}>
                <div className='Banner'>
                    <div className='Container'>
                        <MarkDown className='Info'>{ExampleConfig.brief}</MarkDown>
                    </div>
                </div>
                <div className='Case'>
                    <div className='TitleContainer '>
                        <LBDP_ContentTitle text='示例' english='Demo' />
                    </div>
                    <ExampleKit data={ExampleConfig.ExampleData} />
                </div>
            </div>
        );
    }
}
