import * as React from "react";
import MarkDown from '../Markdown/Markdown'
import LBDP_ContentTitle from '../ContentTitle/ContentTitle'
import PlatformAdvantage from './PlatformAdvan'
import ProductKit from './ProductKit'
import DevelopGuide from './Guide'
import LoginKit from './LoginKit'
import CaseImgKit from './CaseImgKit'
import Button from '@material-ui/core/Button';

import '../../asset/components/Home/styles/home.less'
import '../../asset/declare'
import useFlow from '../../asset/components/Home/res/UseFlow.png'
import buildSDK_img from '../../asset/components/Home/res/product_img_builder.png'
import motorSDK_img from '../../asset/components/Home/res/product_img_motor.png';

const globalData = require('config').globalConfig;
const homeConfig = require('homeConfig');

export default class Home extends React.Component {
    public render(): JSX.Element {
        return (
            <div className='ComponentStyle'>
                <div className='Banner'>
                    <div className='Container' style={{paddingTop: 170, height:286}}>
                        <MarkDown className='MardDown' key="home-brief">{homeConfig.brief}</MarkDown>
                        <LoginKit />
                    </div>
                </div>
                <div className='Platfrom'>
                    <div className='TitleContainer'>
                        <LBDP_ContentTitle text='平台四大优势' english='Four Platform Advantages' />
                    </div>
                    <div className='Container'>
                        <PlatformAdvantage data={homeConfig.platformData}/>
                    </div>
                </div>
                <div className='Product'>
                    <div className='TitleContainer'>
                        <LBDP_ContentTitle text='产品' english='Product' />
                    </div>
                    <div className='Container'>
                        <ProductKit to='/Product/Motor_SDK' image={motorSDK_img} float='left'/>
                        <ProductKit to='/Product/Builder_SDK' image={buildSDK_img} float='right'/>
                    </div>
                </div>
                <div className='HomeCase'>
                    <div className='TitleContainer'>
                        <LBDP_ContentTitle text='案例' english='Case' hasLink={true} btnText='更多示例' to={'/Case'} btnWidth='114px' btnHeight='38px' btnDisplay='none'/>
                    </div>
                    <div className='Container'>
                        <CaseImgKit data={homeConfig.CaseImgs}/>
                    </div>
                </div>
                <div className='Guide'>
                    <div className='TitleContainer'>
                        <LBDP_ContentTitle text='开发指南' english='Developer Guide' />
                    </div>
                    <div className='Container'>
                        <DevelopGuide Motor={homeConfig.HomeGuide.Motor} Builder={homeConfig.HomeGuide.Builder} developGuide={homeConfig.HomeGuide.developGuide}/>
                    </div>
                </div>
                <div className='Flow'>
                <div className='TitleContainer'>
                        <LBDP_ContentTitle text='使用流程' english='UseFlow' hasLink={true} btnText='立刻注册免费试用' btnWidth='160px' btnHeight='38px' btnDisplay="none" href={globalData.registerV3}/>
                    </div>
                    <div className='Container'>
                        <img src={useFlow} alt="UseFlow" />
                    </div>
                    <div className="FlowButton">
                    <Button className='RightNowButton' href={globalData.registerV3} target='_blank'>立刻注册免费试用&gt;&gt;</Button>
                    </div>
                </div>
            </div>
        );
    }
}
