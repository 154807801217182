const globalData = require('config').globalConfig;
const homedata = require('homeData');

export const brief = homedata.brief;

export const platformData = [
    {
        name:"科研创新优势",
        img:"asset/Home/res/platfrom_1.png"
    },
    {
        name:"广泛的应用领域",
        img:"asset/Home/res/platfrom_2.png"
    },
    {
        name:"丰富的技术优势",
        img:"asset/Home/res/platfrom_3.png"
    },
    {
        name:"开放的生态环境",
        img:"asset/Home/res/platfrom_4.png"
    }
];

export const CaseImgs = [
    'asset/Home/res/case1.jpg',
    'asset/Home/res/case2.jpg',
    'asset/Home/res/case3.jpg',
    'asset/Home/res/case4.jpg',
]

export const HomeGuide = {
    Builder:[
        {
            name:"Builder 开发指南",
            url: globalData.Builder.Doc.DevelopGuideUrl
        },
        {
            name:"Builder OpenAPI 使用手册",
            url: globalData.Builder.Doc.OpenAPIUrl
        },
        {
            name:"iWorks JS SDK 使用手册",
            url: globalData.Builder.Doc.iWorksJSSDKUrl
        },
        {
            name:"Moblie SDK 使用手册",
            url: globalData.Builder.Doc.MoblieSDKUrl
        },
        {
            name:'Web Model SDK 使用手册',
            url:globalData.Motor.Doc.WebModelSDKUrlV3
        }
    ],
    Motor:[
        // {
        //     name:"Motor 开发指南",
        //     url: globalData.Motor.Doc.DevelopGuideUrl
        // },
        // {
        //     name:"Motor Trans 使用手册",
        //     url: globalData.Motor.Doc.MotorTransUrl
        // },
        // {
        //     name:"Motor Editor 使用手册",
        //     url: globalData.Motor.Doc.MotorEditorUrl
        // },
        // {
        //     name:"ServerAPI 使用手册",
        //     url: globalData.Motor.Doc.ServerAPIUrl
        // },
        // {
        //     name:"Web Model SDK 使用手册",
        //     url: globalData.Motor.CodeBox
        // },
        // {
        //     name:"Desktop Model JS SDK 使用手册",
        //     url: globalData.Motor.Doc.DesktopModelSDKUrl
        // }

        {
            name:'Motor 开发指南',
            url:globalData.Motor.Doc.WebModelSDKUrlV3
            // url: globalData.Motor.Doc.DevelopGuideUrl
        },   
        {
            name:'Motor Trans 使用手册',
            url: globalData.Motor.Doc.MotorTransUrl
        },
        {
            name:'Motor Web Editor 使用手册',
            url: globalData.Motor.WebEditorGuideV3
            // url: globalData.Motor.Doc.MotorEditorUrl
        },
        {
            name:'Motor JS API 文档',
            url: globalData.Motor.Doc.WebModelSDKUrlV3,
            // url: globalData.Motor.Doc.ServerAPIUrl
        },
        {
            name:'Motor SDK 示例 DEMO',
            url:globalData.Motor.CodeBoxV3,
            // url: globalData.Motor.CodeBox
        },

    ],
    developGuide: homedata.developGuide
};
