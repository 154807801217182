import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        paper: {
            margin: 'auto auto',
            height: 270,
            width: 260,
            marginTop: 15,
            marginBottom: 13,
            boxShadow: '0px 2px 20px 0px rgba(241, 241, 241, 0.9)',
            textAlign: 'center'
        },
        control: {
            padding: theme.spacing(2),
        },
        title:{
            fontSize: '18px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '1px',
            color: '#333333',
            marginTop: '-84px'
        }
    }),
);

interface AdvanProps {
    data: {name: string, img: string}[]
}

export default function PlatformAdvantage(props: AdvanProps) {
    const classes = useStyles({});

    function GetPlatformImg(data: any) : JSX.Element {
        return (
            <React.Fragment>
                <img src={data.img} alt={data.name}/>
                <p className={classes.title}>{data.name}</p>
            </React.Fragment>
        );
    }

    function GetPlatformAdvan(): JSX.Element[] {
        let ret: JSX.Element[] = [];
        for (let i = 0; i < props.data.length; ++i) {
            ret.push(<Grid key={i} item>
                <div className={classes.paper}>
                    {GetPlatformImg(props.data[i])}
                </div>
            </Grid>);
        }
        return ret;
    }

    return (
        <Grid container className={classes.root}  >
            <Grid container justify="center" spacing={5} >
                {GetPlatformAdvan()}
            </Grid>
        </Grid>
    );
}