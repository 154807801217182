import * as React from "react";
import {makeStyles, createStyles} from '@material-ui/core'
import Button from '@material-ui/core/Button';
import {AdapterLink} from "../AdapterLink/AdapterLink"

interface ContentTitleProps {
    text: string,
    english:string,
    splitColor?:string,
    hasLink?: boolean,
    btnText?: string,
    btnWidth?: string,
    btnHeight?: string,
    href?: string,
    to?: string,
    btnDisplay?: string
}


const useStyle = makeStyles(()=>createStyles({
    para:{
        fontSize:'30px',
        width: 'max-content',
        display: 'inline-block',
        paddingBottom:'5px',
        // borderBottom:'1px solid',
        // borderBottomColor:'red'
    },
    eng:{
        marginTop:'5px',
        fontSize:'14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: '0px',
        color: '#666666'
    },
    btn: {
        float: 'right',
        backgroundImage: '',
        backgroundBlendMode: 'normal normal',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'rgba(227, 65, 71, .5)',
        marginTop:-70,
        textAlign:'center',
        '&:hover':{
            backgroundColor: 'rgba(227, 65, 71, 0.2)'
        },
        "& span": {
            fontSize: '16px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '1px',
            color: '#e34147',
            opacity: 0.8
        }

    }
}));

export default function LBDP_ContentTitle(props: ContentTitleProps) {
    const classes = useStyle({});

    function LinkButton() :JSX.Element{
        let ret : JSX.Element = null;
        if(props.hasLink){
            if(props.href){
                ret = (
                    <Button 
                    className={classes.btn} 
                    style={{width:props.btnWidth, height:props.btnHeight, display:props.btnDisplay}}
                    href={props.href} target='_blank'>
                        {props.btnText}
                    </Button>
                );
            }
            else {
                ret = (
                    <Button 
                    className={classes.btn} 
                    style={{width:props.btnWidth, height:props.btnHeight, display:props.btnDisplay}}
                    component={AdapterLink} 
                    to={props.to}>
                        {props.btnText}
                    </Button>
                );
            }
        }
        return ret;
    }

    return (
        <React.Fragment >
            <p className={classes.para}>{props.text}</p>
            <p className={classes.eng}>{props.english}</p>
            {LinkButton()}
        </React.Fragment>
    );
}
