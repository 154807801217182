export const Case1_MD = 
`# 四川九绵高速
九绵高速基于Motor桌面图形引擎开发，融合了DEM、DOM、BIM等多元异构数据，1:1集成工程GIS环境模型，展示了200百多公里的高速公里全貌，在此基础上整合智慧工地相关物联设备：有毒气体、人员定位、视频监控、拌合站、实验室、张拉压浆、超前地质预报、质量检测等数据，使得用户通过可视化的方式实时动态的对高速公里施工进度、质量、安全进行有效综合管理，大幅提升了整体项目的管控水平。`;
export const Case2_MD = 
`# 欧阳街道
欧阳街道采用的社区精细化管理解决方案，在上海东方明珠数字电视有限公司和班联数城的紧密合作下，实现落地应用。基于Motor桌面图形引擎开发平台深度融合城市“神经元”智慧社区系统，利用“数字孪生城市”理念，将街道社区内的所有建筑、道路、环境等进行1:1的三维数字还原，建成智慧社区范围下的建筑时空大数据管理平台，将社区“五违四必”、居民舆情等信息与数字世界的建筑空间位置对应，实现辖区事务的可视化管理。`;
export const Case3_MD = 
`# 杨浦滨江
基于Web端图形引擎开发的某园区智慧管理平台系统，针对当前园区所有楼宇的信息常态显示，如出租率、楼宇数量、可招商面积等；并且对接了园区内视频监控、车辆识别等物联设备，实时展示园区全貌信息。为园区管理、招商展示提供了智慧化管理服务。`;
export const Case4_MD = 
`# 重庆仙桃
重庆仙桃数据谷整体规划面积2平方公里，总建设规模约175万平方米。通过基于Motor桌面图形引擎研发工地管理平台，并与PM、档案、财务、HR等系统对接，真正实现了大平台统一管控。施工管理人员可对建筑信息、塔吊信息，工程进度、安全数据、人员信息等进行可视化监管，大幅提升了整体项目的管控水平。`;
export const Case5_MD = 
`# 贵州建工
贵州建工是基于Builder进行的定制化开发系统，定制研发了需求用量表模块。根据资源类型建立相应的项目需用量计划表，通过提取BIM模型量、导入Excel文件、自定义等方式形成资源明细清单列表。以此为后续一系列的合同签订、采购计划、入库管理、结算管理等流程提供了限制管控的依据，一定程度上避免了资源超量。`;
export const Case6_MD = 
`# 宁波舟山港主通道
宁波舟山港主通道（鱼山石化疏港公路）公路工程主线起于富翅互通，跨越富翅门水道，在岑港镇涨次村南侧设置岑港互通，路线向北延伸，在马目山入海后转向东北，依次跨越长白西航道、舟山中部港域西航道和岱山南航道，在岱山双合登陆。该项目主要针对桥梁检测这一需求进行基于Builder二次开发测点管理、预警管理、统计分析等功能模块。`;