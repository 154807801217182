import * as React from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button'
import Navigation from '../Navigation/Navigation'
import { HashRouter } from "react-router-dom";
import axios from 'axios'
import '../../asset/declare'
import personIcon from '../../asset/components/Header/res/header_pic_default.png'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor:'#f5f5f5',
      width: '100%',
      height: 60,
      boxShadow:'none'
    },
    LoginBox: {
      margin: '0 auto',
      marginTop: -45,
      width: 1180,
      backgroundColor: 'rgba(245, 245, 245)',
      
    },
    buttonArea:{
      paddingLeft:973,
      width: 282,
      backgroundColor: 'rgba(245, 245, 245)',
    },
    button:{
      border: '1px solid rgba(102, 102, 102, 0.2)',
      height: 32,
      marginRight: 5,
      '& span' : {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: '0px',
        color: '#000000',
        opacity: 0.9
      },
      '&:hover. &:focus' : {
        border: 'none',
        color: '#ffffff',
        backgroundColor: 'rgba(183, 148, 93)'
      }
    }
  }),
);

function LBDP_Header() : JSX.Element {
  const classes = useStyles({});
  const globalData = require('config').globalConfig;
  let [scroll, setScroll] = React.useState(false);
  let bLogin =false;

  window.onscroll = function() {
    setScroll(window.pageYOffset >= 70);
  }

  let arr,reg=new RegExp("(^| )"+'ldbp_curToken'+"=([^;]*)(;|$)");
  let token : string = null;
  if(arr=document.cookie.match(reg)){
    token =  decodeURI(arr[2]);
  }
  bLogin = (token != null && token != 'null' && token != '');
  
  function GetLoginBox() : JSX.Element {
    return (
      <div className={classes.LoginBox}>    
        <div className={classes.buttonArea}>
            <Button className={classes.button} key='dashboard' href={globalData? globalData.dashboardV3 : ''} target='_blank'>控制台</Button>
            <Button className={classes.button} key='login' href={globalData? globalData.loginV3 + "?from": ''} target='_blank'>登录</Button>
            <Button className={classes.button} key='register' href={globalData? globalData.registerV3 + "?from" : ''} target='_blank'>注册</Button>
        </div>
      </div>
    )
  }
  
  function GetLogInfo() : JSX.Element {
    return (
      <div className={classes.LoginBox}>    
        <div className={classes.buttonArea}>
            <Button className={classes.button} key='dashboard' href={globalData? globalData.dashboardV3 : ''} target='_blank'>控制台</Button>
            <img alt="Profile Picture" src={personIcon} style={{float:'right', marginRight:150}}/>
        </div>
      </div>
    )
  }

  return (
      <AppBar className={classes.root} style={scroll ? {backgroundColor:'#f5f5f5'} : {}}>
        <HashRouter>
          <Navigation />
        </HashRouter>
        {bLogin ? GetLogInfo() : GetLoginBox()}
      </AppBar>
  );
}

export default LBDP_Header;
