import * as React from "react"
import MarkDown from '../Markdown/Markdown'
import LBDP_ContentTitle from '../ContentTitle/ContentTitle'
import DocumentationKit from './DocumentationKit'

const DocumentationConfig = require('docConfig')
const globalData = require('config').globalConfig;

const homeConfig = require('homeConfig');
import DevelopGuide from './Guide'
export default class Documentation extends React.Component {
    constructor(props: any) {
        super(props);
    }
    public render(): JSX.Element {
        return (
            <div className='ComponentStyle'>
                <div className='Banner'>
                    <div className='Container'>
                        <MarkDown className='Info'>{DocumentationConfig.brief}</MarkDown>
                    </div>
                </div>
                <div className='Guide' style={{backgroundColor:'#fff'}}>
                    <div className='Container'>
                        <DevelopGuide Motor={DocumentationConfig.DocumentationData.Motor} Builder={DocumentationConfig.DocumentationData.Builder} developGuide={homeConfig.HomeGuide.developGuide}/>
                    </div>
                </div>

                
{/* 
                
                <div className='TitleContainer' style={{marginTop:70}}>
                    <LBDP_ContentTitle text='Motor开发组件' english='Developer Components' hasLink={true} btnText='前往下载' btnWidth='114px' btnHeight='38px' href={globalData.GitHub.GitHubAll}/>
                </div>
                <div className='HomeContainer' style={{ marginTop: -20}}>
                    <div className='Container'>
                        <DocumentationKit data={DocumentationConfig.DocumentationData.Motor} col={3} />
                    </div>
                </div>
                <div className='TitleContainer' style={{paddingTop: 20}}>
                    <LBDP_ContentTitle text='Builder开发组件' english='Developer Components' hasLink={true} btnText='前往下载' btnWidth='114px' btnHeight='38px' href={globalData.GitHub.GitHubAll}/>
                </div>

                
                <div className='HomeContainer' style={{ marginTop: -20, marginBottom: 140}}>
                    <div className='Container'>
                        <DocumentationKit data={DocumentationConfig.DocumentationData.Builder} col={3} />
                    </div>
                </div>
 */}


            </div>

        );
    }
}
