import * as React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MarkDown from '../Markdown/Markdown'
import ModalWaitTip from '../WaitModal/WaitModal'
import '../../asset/declare'
import txtImg from '../../asset/components/Home/res/guide_img_txt.png'
import txtImg1 from '../../asset/components/Home/res/guide_img_txt1.png'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            margin: 'auto auto',
   
            width: '48%',
           
            position: 'relative',
           
        },
        layout:{
            backgroundColor: 'rgb(245, 245, 245)',
            overflow: 'hidden',
            minHeight:300,
        },
        icon: {
            display: 'block',

            width: 0,
            height: 0,
            borderTop: '20px solid #b7945d',
            borderRight: '20px solid transparent',
            position: 'absolute',
            left: 0,
            top: 0
        },
        triangle: {
            width: 0,
            height: 0,
            borderTop: '100 solid red',
            borderRight: '100 solid transparent',
        },
        container: {
            margin: '0 auto',
            marginTop: -364,
            marginLeft: 30,
            width: 337,
            height: 364,
            "& h6": {
                fontSize: '18px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                color: '#333333',
                marginTop: 45,
                marginBottom: 38
            },
            "& p": {
                marginBottom: 6,
            },
        },
        control: {
            padding: theme.spacing(2),
        },
        markdown: {
            margin: '0 20px',
            "& a": {
                fontSize: '14px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                lineHeight: '22px',
                letterSpacing: '0px',
                color: '#666666',
                "&:link,:visited": {
                    textDecoration: 'none'
                },
                "&:hover": {
                    color: '#b7945d'
                }
            },
            "& p": {
                fontSize: '14px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                lineHeight: '30px',
                letterSpacing: '0px',
                color: '#666666',
                marginBottom: 6,
                borderBottom: '1px solid rgb(234, 234, 234)',
                paddingLeft: 20,
            },
            "& h6": {
                marginTop: 45,
                paddingBottom: 10,
                marginBottom: 10,
                paddingLeft: 20,
                fontSize: '18px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                letterSpacing: '1px',
                color: '#333333',
                borderBottom: '1px solid rgb(234, 234, 234)',
            },
            "& span": {
                display: 'inline-block',
                position: 'relative',
                width: 10,
                height: 18,
                marginRight: 10,
                color: '#333333',
                "&::after": {
                    display: 'inline-block',
                    content: "",
                    height: 13,
                    width: 13,
                    borderWidth: '3 3 0 0',
                    borderColor: '#0177ff',
                    borderStyle: 'solid',
                    transform: 'matrix(0.71, 0.71, -0.71, 0.71, 0, 0)',
                    position: 'absolute',
                    top: '50%',
                    left: 150,
                }
            },


        },
        icons: {
            width: 10,
            height: 10,
            marginRight: '10px !important',
        },
        download:{
            display: 'block',
            border: '1px solid #b7945d',
            borderRadius: '5px',
            width:100,
            lineHeight: '30px',
            textAlign: 'center',
            fontSize: '14px',
            color: '#b7945d',
            textDecoration: 'none',
            margin:'20px auto ',
            '&:hover': {
                backgroundColor: 'rgba(183 ,148 ,93 ,.1)'
            }
        }
    }),
);

interface DevelopGuideProps {
    Motor: {
        name: string;
        url: string;
    }[],
    Builder: {
        name: string;
        url: string;
    }[],
    developGuide: string
}

export default function DevelopGuide(props: DevelopGuideProps) {
    const classes = useStyles({});

    function GetGuideData(data: {
        name: string;
        url: string;
    }[]): JSX.Element {
        let ret: JSX.Element[] = [];
        const [isHover, setIsHover] = React.useState([]);
        for (let i = 0; i < data.length; ++i) {
            const curData = data[i];
            if (curData.url == null || curData.url.length == 0) {
                ret.push(<ModalWaitTip obj={<p key={curData.name}>{curData.name}</p>} />);
            }
            else {
                ret.push(<p key={curData.name}
                    onMouseEnter={
                        () => {
                            let arr: number[] = [];
                            arr[i] = 0
                            setIsHover(arr)
                        }
                    }
                    onMouseLeave={() => {
                        let arr: number[] = [];
                        arr[i] = 1
                        setIsHover(arr)
                    }}>
                    <span className={classes.icons}>
                        <img src={isHover[i] === 0 ? txtImg1 : txtImg} />
                    </span>
                    <a href={curData.url} target='_blank'>{curData.name} </a></p>);
            }
        }
        return (
            <React.Fragment>
                {ret}
            </React.Fragment>
        )
    }

    return (
        <Grid container className={classes.root}  >
            <Grid container justify="center" spacing={5} style={{ marginTop: 0 }}>
                <div className={classes.paper}>
                <div  className={classes.layout}>
                    <div className={classes.icon}></div>
                    <div className={classes.markdown}>
                        <h6>Motor开发组件</h6>
                        {GetGuideData(props.Motor)}
                     
                    </div>
                    </div>
                    <a className={classes.download} href="https://github.com/lubansoft-developer-platform" target="_blank">前往下载</a>
                </div>
                <div className={classes.paper}>
                    <div  className={classes.layout}>
                    <div className={classes.icon}></div>
                    <div className={classes.triangle}></div>
                    <div className={classes.markdown}>
                        <h6>Builder开发组件</h6>
                        {GetGuideData(props.Builder)}
                    </div>
                    </div>
                    <a className={classes.download} href="https://github.com/lubansoft-developer-platform" target="_blank">前往下载</a>
                </div>
            </Grid>
        </Grid>
    );
}
