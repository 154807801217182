export const brief =
`# 鲁班开发者平台价格
支持多种部署方式和收费模式

入门到高级一应俱全`

export const MotorPriceData = [
    {
        titleImage: 'asset/Price/res/motorLite.png',
        title: 'Motor Lite公有云',
        feature: [
            'Motor基础数据服务器及开发工具',
            'Motor网页图形引擎及开发工具',
            '数据存储空间 5G',
            '工程授权数 10个',
            '接口访问次数 无限',
            '在线技术支持',
            '一对一专属客服'
        ],
        price: '7,000',
        unit: '元/年起',
    },
    {
        titleImage: 'asset/Price/res/motorStandard_public.png',
        title: 'Motor Standard公有云',
        feature: [
            'Motor基础数据服务器及开发工具',
            'Motor网页图形引擎及开发工具',
            'Motor桌面图形引擎及开发工具',
            '数据存储空间 5G',
            '工程授权数 10个',
            '接口访问次数 无限',
            '在线技术支持',
            '一对一专属客服',
            '培训课程 5人·日'
        ],
        price: '100,000',
        unit: '元/年起'
    },
    {
        titleImage: 'asset/Price/res/motorStandard_private.png',
        title: 'Motor Standard私有云',
        feature: [
            'Motor基础数据服务器及开发工具',
            'Motor网页图形引擎及开发工具',
            'Motor桌面图形引擎及开发工具',
            '工程授权数 20个',
            '接口访问次数 无限',
            '在线技术支持',
            '一对一专属客服',
            '培训课程 5人·日'
        ],
        price: '立即询价',
        unit: '',
        tel: '150-2113-6689'
        // warn: '*版本升级收取 15%升级费'
    }
]

export const BuilderPriceData = {
    feature: [
        {
            Image:'asset/Price/res/price_ic_builderServer.png',
            Text: 'Builder服务器接口工具'
        },
        {
            Image:'asset/Price/res/price_ic_iWorks.png',
            Text: 'iWorks插件开发工具'
        },
        {
            Image:'asset/Price/res/price_ic_app.png',
            Text: 'APP插件开发工具'
        },
        {
            Image:'asset/Price/res/price_ic_onLine.png',
            Text: '在线技术支持'
        },
        {
            Image:'asset/Price/res/price_ic_service.png',
            Text: '一对一专属客服'
        },
        {
            Image:'asset/Price/res/price_ic_course.png',
            Text: '培训课程 5人·日'
        }
    ],
    price: '立即询价',
    warn: '',
    tel: '150-2113-6689'
}

export const ServiceData = {
    text: '技术支持',
    tel: '021-35885000-8064',
    services: [
        {
            Image: 'asset/Price/res/service_ic_jianmo.png',
            Text: '建模服务'
        },
        {
            Image: 'asset/Price/res/service_ic_kaifa.png',
            Text: '开发服务'
        },
        {
            Image: 'asset/Price/res/service_ic_xiangmu.png',
            Text: '项目服务'
        },
        {    
            Image: 'asset/Price/res/service_ic_peixun.png',
            Text: '培训服务'
        }
    ]
}
