import * as React from 'react'
import Button from "@material-ui/core/Button"


interface ConnectButtonProps {
    text: string;
    tel: string;
    classes?:Record<"button" | "buttonDiv" | "buttonTel", string>
}

export default function ConnectButton(props: ConnectButtonProps) : JSX.Element{
    let [bShowTel , setShowTel] = React.useState(false);

    return (
        <div className={props.classes.buttonDiv}>
            { 
                bShowTel ? <Button className={props.classes.buttonTel} onClick={(e) => {setShowTel(false);}} >{props.tel}</Button>
                : <Button className={props.classes.button} onClick={(e) => {setShowTel(true);}}>{props.text}</Button>
            }
        </div>
    )
}