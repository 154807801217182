export const globalConfig = {
    serverUrl: "https://open.lubansoft.com/api/motor/v1.0/service",
    serverUrlV3:"https://open.lubansoft.com/api/motor/v2",
    dashboard: "https://lbdp.lubansoft.com/center/center.html", 
    // dashboardV3:"https://lbdp.lubansoft.com/center-new/#/center",
    dashboardV3:"https://lbdp.lubansoft.com/center-new/index.html",
    login: "https://lbdp.lubansoft.com/center/index.html", 
    loginV3: "https://lbdp.lubansoft.com/center-new/index.html#/",
    register:"https://lbdp.lubansoft.com/center/register.html", 
    registerV3:"https://lbdp.lubansoft.com/center-new/index.html#/register",
    Motor:{
        Doc: {
            DevelopGuideUrl: "https://lbdp.lubansoft.com/reference/motor-dev-guide/index.html",
            ServerAPIUrl:"https://lbdp.lubansoft.com/reference/motor-rest-api/index.html", 
            WebModelSDKUrl:"https://lbdp.lubansoft.com/reference/motor-web-api/index.html",
            DesktopModelSDKUrl:"https://lbdp.lubansoft.com/reference/motor-desktop-api/index.html",
            MotorEditorUrl:"https://github.com/lubansoft-developer-platform/Motor-Editor/blob/master/Motor%E7%BC%96%E8%BE%91%E5%99%A8%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf",
            MotorTransUrl:"https://github.com/lubansoft-developer-platform/Motor-Trans",
            WebModelSDKUrlV3:"https://lbdp.lubansoft.com/reference/v3.0/motor-web-api/index.html"
        },
        WebEditorGuideV3:'https://pan.baidu.com/s/1jxT3zJV-G1iVwnaDBJpCOQ?pwd=ckay',
        CodeBox:"https://lbdp.lubansoft.com/code-box/motor/index.html",
        CodeBoxV3:"https://lbdp.lubansoft.com/code-box/motor/index.html",
        Example:"https://lbdp.lubansoft.com/example/",
        ExampleV3_1:"https://lbdp.lubansoft.com/motor-sdk/#/viewer?id=875693b930f149368eea779f4fdaef35&appId=ZDBiM2M2MWM2NjM5NDM0ZTg0OTAwYjFmZDhkMzkxY2I=&secret=NDU5ZGM4Yjc3YTYzYTBjMDA5YWVjMjdmODE4ZmViZjY=&type=project",
        ExampleV3_2:"https://lbdp.lubansoft.com/motor-sdk/#/viewer?id=d0feccf96115400087300e7c5391b0d8&appId=ZDBiM2M2MWM2NjM5NDM0ZTg0OTAwYjFmZDhkMzkxY2I=&secret=NDU5ZGM4Yjc3YTYzYTBjMDA5YWVjMjdmODE4ZmViZjY=&type=project",
    },
    Builder:{
        Doc: {
            DevelopGuideUrl:"https://lbdp.lubansoft.com/reference/builder-dev-guide/index.html", 
            OpenAPIUrl:"https://lbdp.lubansoft.com/reference/build-openapi/index.html", 
            iWorksJSSDKUrl:"https://lbdp.lubansoft.com/reference/build-iworkssdk/index.html",
            MoblieSDKUrl:"https://lbdp.lubansoft.com/reference/build-mobilesdk/index.html"
        },
        CodeBox:"",
        Example:""
    },
    GitHub:{
        iWorksJSSDK:"https://github.com/lubansoft-developer-platform/iWorks-JS-SDK",
        iWorksJSSDKDemo:"https://github.com/lubansoft-developer-platform/iWorks-JS-SDK/tree/master/demo",
        BuilderMobileSDK:"https://github.com/lubansoft-developer-platform/Builder-Mobile-SDK",
        BuilderMobileDemo:"https://github.com/lubansoft-developer-platform/Builder-Mobile-SDK/tree/master/demo",
        DesktopModelSDK:"https://github.com/lubansoft-developer-platform/MotorDesktopGraphicsEngine",
        DesktopModelSDKDemo:"https://github.com/lubansoft-developer-platform/MotorDesktopGraphicsEngine/tree/master/demo",
        WebModelSDK:"https://github.com/lubansoft-developer-platform/MotorWebGraphicsEngine",
        WebModelSDKDemo:"https://github.com/lubansoft-developer-platform/MotorWebGraphicsEngine/tree/master/example/",
        GitHubAll:"https://github.com/lubansoft-developer-platform"
    }
}
