const globalData = require('config').globalConfig;

const MotorIcon = 'asset/Example/res/example_motor.png'
const BuilderIcon = 'asset/Example/res/example_builder.png'

export const brief = ``

export const ExampleData = [
    {
        title: '某智慧工地',
        brief: '某园区第三施工段，使用Motor Web SDK承载地形数据、精细BIM模型数据，对接开发项目管理数据及应用。',
        typeIcon: MotorIcon,
        image: 'asset/Example/res/v303.png',
        interface:[
            "加载CIM数据",
            "CIM/BIM场景切换",
            "BIM工具组合"
        ],
        bWebApp: true,
        url: globalData.GitHub.WebModelSDKDemo+"cim_example",
        // downloadUrl: globalData.Motor.Example + "cim_example/index.html",
        downloadUrl: globalData.Motor.ExampleV3_1,
    },
    {
        title: '某智慧展厅',
        brief: '使用Motor Web SDK承载BIM模型数据，精细化展示各楼层模型数据信息。',
        typeIcon: MotorIcon,
        image: 'asset/Example/res/v304.png',
        interface:[
            "加载BIM数据",
            "构件树",
            "剖切"
        ],
        bWebApp: true,
        url: globalData.GitHub.WebModelSDKDemo+"bim_example",
        downloadUrl:globalData.Motor.ExampleV3_2,
        // downloadUrl: globalData.Motor.Example + "bim_example/index.html"
    },
    {
        title: '上海三维大数据demo',
        brief: '使用Motor Web SDK展示上海市核心区域三维场景模型，利用大数据结合三维模型，使数据可视化效果更佳。',
        typeIcon: MotorIcon,
        image: 'asset/Example/res/demo2.png',
        interface:[
            "加载地图数据",
            "浮动组合图标",
            "飞跃弧线、扩散渐变特效"
        ],
        bWebApp: true,
        url: globalData.GitHub.WebModelSDKDemo+"citydata",
        downloadUrl: globalData.Motor.Example + "citydata/index.html"
    },
    {
        title: '基坑监测',
        brief: '基于Builder进行基坑监测功能二次开发，在BIM模型上可自由设置监测测点，根据测点信息，生成监测报告及预消警报告。',
        typeIcon: BuilderIcon,
        image: 'asset/Example/res/demo5.png',
        interface:[
            "测点管理",
            "测点信息编辑",
            "测点信息反查"
        ],
        bWebApp: false,
        url: globalData.GitHub.iWorksJSSDKDemo,
        downloadUrl: globalData.GitHub.iWorksJSSDK
    },
    {
        title: '手机视频监控应用',
        brief: '使用iWorks APP定制开发视频监控模块，可以实时监控工地情况。每个监控点可关联工程、构件类型或具体的某些构件。',
        typeIcon: BuilderIcon,
        image: 'asset/Example/res/demo6.png',
        interface:[
            "监控点关联构件",
            "监控详情页面跳转",
            "关联信息反查"
        ],
        bWebApp: false,
        url: globalData.GitHub.BuilderMobileDemo,
        downloadUrl: globalData.GitHub.BuilderMobileSDK
    }
]
