const productData = require('productData');
export const CharaterConfig = {
    Motor:[
        {
            Text: '无边界世界',
            Tab: 'asset/Product/res/wubianjie.png',
            TabHover:'asset/Product/res/wubianjie_hover.png',
            md: productData.CharaterData.Motor.BorderlessWorld_MD,
            // Image:'asset/Product/res/wubianjie_character.png'
        },
        {
            Text: '超强渲染性能',
            Tab: 'asset/Product/res/xuanran.png',
            TabHover: 'asset/Product/res/xuanran_hover.png',
            md: productData.CharaterData.Motor.RenderPerformance_MD,
            // Image: 'asset/Product/res/xuanran_character.png'
        },
        {
            Text: 'BIM+CIM',
            Tab: 'asset/Product/res/BimCim.png',
            TabHover: 'asset/Product/res/BimCim_hover.png',
            md: productData.CharaterData.Motor.BimCim_MD,
            // Image: 'asset/Product/res/BimCim_character.png'
        },
        {
            Text: '安全可控',
            Tab: 'asset/Product/res/kuapingtai.png',
            TabHover: 'asset/Product/res/kuapingtai_hover.png',
            md: productData.CharaterData.Motor.CrossPlatform_MD,
            // Image: 'asset/Product/res/kuapingtai_character.png'
        },
        {
            Text: '多元异构融合',
            Tab: 'asset/Product/res/duoyuan.png',
            TabHover: 'asset/Product/res/duoyuan_hover.png',
            md: productData.CharaterData.Motor.Polyisomerization_MD,
            // Image: 'asset/Product/res/duoyuan_character.png'
        }
    ],
    Builder:[
        {
            Text: '工程文件格式转化',
            Tab: 'asset/Product/res/gongcheng.png',
            TabHover: 'asset/Product/res/gongcheng_hover.png',
            md: productData.CharaterData.Builder.ProjectFormat_MD,
            Image: 'asset/Product/res/gongcheng_character.png'
        },
        {
            Text: '模型/图纸轻量化',
            Tab: 'asset/Product/res/qinglianghua.png',
            TabHover: 'asset/Product/res/qinglianghua_hover.png',
            md: productData.CharaterData.Builder.LOD_MD,
            Image: 'asset/Product/res/qinglianghua_character.png'
        },
        {
            Text: 'BIM数据管理',
            Tab: 'asset/Product/res/shujuguanli.png',
            TabHover: 'asset/Product/res/shujuguanli_hover.png',
            md: productData.CharaterData.Builder.BIMMgr_MD,
            Image: 'asset/Product/res/shujuguanli_character.png'
        }
    ]
};

export const SupportConfig = {   
    Motor:[
        {
            Image: 'asset/Product/res/Motor_anli.png',
            Text:'Motor案例',
            To:'/Case'
        },
        {
            Image: 'asset/Product/res/Motor_shili.png',
            Text:'Motor示例',
            To:'/Example'
        },
        {
            Image: 'asset/Product/res/Motor_wendang.png',
            Text:'Motor文档',
            To:'/Documentation'
        }
    ],
    Builder:[
        {
            Image: 'asset/Product/res/Motor_anli.png',
            Text:'Builder二开案例',
            To:'/Case'
        },
        {
            Image: 'asset/Product/res/Motor_shili.png',
            Text:'Builder二开示例',
            To:'/Example'
        },
        {
            Image: 'asset/Product/res/Motor_wendang.png',
            Text:'Builder二开文档',
            To:'/Documentation'
        }
    ]
}
