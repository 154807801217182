import * as React from "react";
import { withRouter, RouteComponentProps } from 'react-router'
import { createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { AdapterLink } from '../AdapterLink/AdapterLink'
import '../../asset/components/Navigation/style/navStyle.less'
import '../../asset/declare'
import Header_logo from '../../asset/components/Navigation/res/logo.png'
import smallLogo from '../../asset/components/Header/res/logo-small.png'

export interface NavItem {
    id: string,
    hasSubMenu: boolean,
    navItem: string,
    NavItems?: NavItem[],
    path: string
}
export interface NavProps extends RouteComponentProps {
    data?: NavItem[]
}

interface NavStates {
    prevOpen: boolean,
    arrowRef: HTMLElement,
    routepath: string
}

const classes = createStyles({
    root: {
        width: 1180,
        height: 36,
        margin: '12px auto',
        backgroundColor: '#f5f5f5'
    },
    brand: {
        width: 450,
        height: 40,
        marginRight: 100,
        justifyContent: 'flex-start',
        textTransform: 'none'
    },
    selected: {
        // opacity: 1,
        // borderBottom: '2px solid red',
        // borderRadius: 0
        border: 'none',
        color: 'rgb(183, 148, 93)',
        // backgroundColor: 'rgba(183, 148, 93)'

    },
    menuSelected: {
        opacity: 1,
        borderBottom: '1px solid #b7945d'
    },
    popper: {
        marginTop: 8,
        backgroundColor: '#f5f5f5',
        borderRadius: 0,
        width: 174,
        height: 100,
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: '0px',
        color: '#ffffff',
        // opacity: 0.8
    },
    popper1: {
        marginTop: 8,
        backgroundColor: '#f5f5f5',
        borderRadius: 0,
        width: 174,
        height: 50,
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: '0px',
        color: '#ffffff',
        // opacity: 0.8
    }
});

class Navigation extends React.Component<NavProps, NavStates> {

    constructor(props: NavProps, state: NavStates) {
        super(props, state)
        this.state = { prevOpen: false, arrowRef: null, routepath: this.props.location.pathname, navIndex: 0 };
        this.handleToggle = this.handleToggle.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseAway = this.handleCloseAway.bind(this);
    }

    componentWillReceiveProps(nextProps: NavProps) {
        this.setState({ routepath: this.props.location.pathname })
    }

    private handleToggle(index: number): void {
        if (index != this.state.index && this.state.prevOpen) {
            this.state.prevOpen = false
        }
        setTimeout(() => {
            this.setState({ prevOpen: !this.state.prevOpen, navIndex: index });
        })

    }
    private scrollBottom(){
        setTimeout(() => {
            let h = document.querySelector('.Banner').offsetHeight - 20
            let i = 0
            let setIntervals = setInterval(() => {
                i += 30
                window.scrollTo(0, i)
                if (i >= h) {
                    clearInterval(setIntervals)
                    return
                }
            }, 1000 / 60)

        })
    }
    private handleClose(event: React.MouseEvent<EventTarget>): void {
        this.setState({ prevOpen: false });
       this.scrollBottom()

    }

    private handleCloseAway(event: React.MouseEvent<EventTarget>): void {
        if (this.anchorRef && this.anchorRef.contains(event.target as HTMLElement)) {
            return;
        }
        this.setState({ prevOpen: false });
    };

    // Popper 箭头实例
    private handleArrowRef = (node: HTMLElement) => {
        this.setState({
            arrowRef: node,
        });
    };

    public render(): JSX.Element {
        return (
            <div style={classes.root}>
                <Button component={AdapterLink} to='/' style={classes.brand} onClick={() => { this.setState({ prevOpen: false }); }}>
                    <img src={smallLogo} />
                    <div
                        className='logo_title'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: -10,
                            lineHeight: 1.1,
                            transform: 'scale(0.8)',
                        }}
                    >
                        <span
                            style={{
                                fontSize: 20,
                                fontWeight: 800,
                            }}
                        >
                            鲁班开发者平台
                        </span>
                        <span>Luban Developer Platform</span>
                    </div>
                    <div style={{
                        float: 'left',
                        width: 1,
                        height: 25,
                        background: '#000',
                    }}>
                    </div>

                    <div>
                        <span
                            style={{
                                fontSize: 15,
                                fontWeight: 500,
                                paddingLeft: 10,
                            }}
                        >
                            引擎驱动未来 数字链接世界
                        </span>
                    </div>
                </Button>
                <Button
                    ref={node => { this.anchorRef0 = node }}
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    component='div'
                    className='NavButton'
                    onClick={() => { this.handleToggle(0) }}
                    style={(this.props.location.pathname == '/Product/Motor_SDK' || this.props.location.pathname == '/Product/Builder_SDK') ? classes.selected : {}}
                >产品</Button>
                <Button component={AdapterLink} to='/Case' onClick={this.handleClose} className='NavButton' style={this.props.location.pathname == '/Case' ? classes.selected : {}} >案例</Button>

                <Button component={AdapterLink} to='/Example' onClick={this.handleClose} className='NavButton'
                    ref={node => { this.anchorRef1 = node }}
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={() => {
                        this.handleToggle(1)
                        this.scrollBottom()
                    }}
                    style={this.props.location.pathname == '/Example' ? classes.selected : {}}
                >示例</Button>

                {/* <Button component={AdapterLink} to='/Price' onClick={this.handleClose} className='NavButton' style={ this.props.location.pathname== '/Price' ? classes.selected : {}}>价格</Button> */}
                <Button component={AdapterLink} to='/Documentation' onClick={this.handleClose} className='NavButton' style={this.props.location.pathname == '/Documentation' ? classes.selected : {}}>开发文档</Button>
                <Popper open={this.state.prevOpen} anchorEl={this['anchorRef' + this.state.navIndex]} transition disablePortal
                    placement="bottom"
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        arrow: {
                            enabled: true,
                            element: this.state.arrowRef,
                        },
                    }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <React.Fragment>
                                <span className="menu-arrow" ref={this.handleArrowRef} />
                                <Paper id="menu-list-grow" style={classes['popper' + (this.state.navIndex == 0 ? '' : '1')]}>
                                    <ClickAwayListener onClickAway={this.handleCloseAway}>
                                        <MenuList style={{ padding: 0, width: "100%", height: "100%" }}>
                                            {this.state.navIndex == 0 &&
                                                <MenuItem component={AdapterLink} to='/Product/Motor_SDK' className="MenuButtonMotor" style={this.props.location.pathname == '/Product/Motor_SDK' ? classes.menuSelected : {}} onClick={this.handleClose}>鲁班 Motor 开发组件</MenuItem>}
                                            {this.state.navIndex == 0 && <MenuItem component={AdapterLink} to='/Product/Builder_SDK' className="MenuButtonBuilder" style={this.props.location.pathname == '/Product/Builder_SDK' ? classes.menuSelected : {}} onClick={this.handleClose}>鲁班 Builder 开发组件</MenuItem>
                                            }
                                            {this.state.navIndex == 1 &&
                                                <MenuItem component={AdapterLink} to="" className="MenuButtonMotor"
                                                    onClick={() => {
                                                        this.handleClose();
                                                        window.open('https://lbdp.lubansoft.com/code-box/motor/index.html')
                                                    }}>Motor SDK 示例DEMO</MenuItem>}
                                        </MenuList>

                                    </ClickAwayListener>
                                </Paper>
                            </React.Fragment>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }
    private anchorRef: any;
}

export default withRouter(Navigation)
