import * as React from 'react';
import {Link} from "react-router-dom";


interface ProductKitProps{
    to?: string,
    image: string,
    float: any
}

export default class ProductKit extends React.Component<ProductKitProps> {
    constructor(props:ProductKitProps){
        super(props);
    }
    public render() : JSX.Element {
        const useStyles = {
            paper:{
                width: 570,
                height: 190,
                float: this.props.float,
                backgroundColor: '#ffffff',
                boxShadow: '0px 2px 20px 0px rgba(241, 241, 241, 0.9)'
            }
        }
        return(
            <div style={useStyles.paper}>
                <Link to={this.props.to}>
                    <img src={this.props.image} width='100%' height='100%'/>
                </Link>
            </div>
        );
    }
}