import * as React from "react";
import Button from '@material-ui/core/Button';
import '../../asset/components/Footer/styles/footer.less'
import '../../asset/declare'
import website_logo from '../../asset/components/Footer/res/website.png'
import wxcode_logo from '../../asset/components/Footer/res/wxcode.png'
import beian_logo from '../../asset/components/Footer/res/beian.png'

const globalData = require('config').globalConfig;

class LBDP_Footer extends React.Component {
    render(): JSX.Element {
        return (
            <div className='LDBP_Footer'>
                <div className='Ad'>
                    <div className='Container'>
                        <p>注册即可享受30天免费试用！</p>
                        <Button className='StartButton' href={globalData.dashboardV3} target='_blank'>立刻开始</Button>
                    </div>
                </div>
                <div className='Footer'>
                    <div className='Container'>
                        <div className='Info'>
                            <div className="AddInfo">
                                <p className="CoLtd">鲁班软件股份有限公司</p>
                                <p className="Address">地址：上海市静安区康宁路288弄2号1幢12-13楼</p>
                                <p className="Address">电话：021-35885000</p>
                                <p className="Address">传真：021-35885028</p>
                                <p className="Address">E-mail：bim@luban.cn</p>
                            </div>
                        </div>

                        <div className='footLogo'>
                        <img src={wxcode_logo} alt='footlogo'/>
                        <div
                            style={{color: '#ffffff',opacity: 0.9,display: 'flex',flexDirection: 'column',lineHeight: 1.1,transform: 'scale(0.8)',}}>
                            <span>关注新鲁班和<br/>10万粉丝学习互动</span>
                        </div>
                        </div>

                        <div className='footLogo'>
                        <img src={website_logo} alt='footlogo'/>
                        <div
                            style={{color: '#ffffff',opacity: 0.9,display: 'flex',flexDirection: 'column',lineHeight: 1.1,transform: 'scale(0.8)',}}>
                            <span>关注鲁班软件官微<br/>了解更多</span>
                        </div>
                        </div>
                        
                    </div>
                </div>
                <div className='Ext'>
                    <div className="icpClass">
                        <p><a href="http://beian.miit.gov.cn" target="_blank" style={{color: "white", textDecoration: "none"}}>沪ICP备09024855号-14</a>   &nbsp;&nbsp;&nbsp;&nbsp; &copy;1999-鲁班软件股份有限公司版权所有</p>
                    </div>
                    <div className='notice icpClass'>
                        <p>亲爱的市民朋友，上海警方反诈劝阻电话</p>
                        <p>“962110”系专门针对避免您财产被骗受损而设，</p>
                        <p>请您一旦收到来电，立即接听。</p>
                    </div>
                    <div className='beian icpClass'>
                        <img style={{float:'left'}} src={beian_logo} alt='footlogo'/>
                        <p style={{float:'left'}}>沪公网安备31011002000022</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default LBDP_Footer;
