const globalData = require('config').globalConfig;

export const brief = ``

export const DocumentationData = {
    Motor:[
        {
            image:'asset/Documentation/res/developGuide_motor.png',
            name:'Motor 开发指南',
            url:globalData.Motor.Doc.WebModelSDKUrlV3
            // url: globalData.Motor.Doc.DevelopGuideUrl
        },   
        {
            image:'asset/Documentation/res/trans_motor.png',
            name:'Motor Trans 使用手册',
            url: globalData.Motor.Doc.MotorTransUrl
        },
        {
            image:'asset/Documentation/res/editor_motor.png',
            name:'Motor Web Editor 使用手册',
            url: globalData.Motor.WebEditorGuideV3
            // url: globalData.Motor.Doc.MotorEditorUrl
        },
        {
            image:'asset/Documentation/res/serverApi_motor.png',
            name:'Motor JS API 文档',
            url: globalData.Motor.Doc.WebModelSDKUrlV3,
            // url: globalData.Motor.Doc.ServerAPIUrl
        },
        {
            image:'asset/Documentation/res/webSDK_motor.png',
            name:'Motor SDK 示例 DEMO',
            url:globalData.Motor.CodeBoxV3,
            // url: globalData.Motor.CodeBox
        },
        // {
        //     image: '',
        //     name:'',
        //     url: ''
        // }
    ],
    Builder:[
        {
            image:'asset/Documentation/res/developGuide_builde.png',
            name:'Builder 开发指南',
            url: globalData.Builder.Doc.DevelopGuideUrl
        },
        {
            image:'asset/Documentation/res/openApi_builder.png',
            name:'Builder OpenAPI 使用手册',
            url: globalData.Builder.Doc.OpenAPIUrl
        },
        {
            image:'asset/Documentation/res/iworkJS_builder.png',
            name:'iWorks JS SDK 使用手册',
            url: globalData.Builder.Doc.iWorksJSSDKUrl
        },
        {
            image:'asset/Documentation/res/mobile_SDK_builder.png',
            name:'Mobile SDK 使用手册',
            url: globalData.Builder.Doc.MoblieSDKUrl
        },
        {
            image:'asset/Documentation/res/webSDK_motor.png',
            name:'Web Model SDK 使用手册',
            url:globalData.Motor.Doc.WebModelSDKUrlV3,
            // url: globalData.Motor.CodeBox
        },
        // {
        //     image: 'asset/Documentation/res/desktopSDK_motor.png',
        //     name:'Desktop Model JS SDK 使用手册',
        //     url: globalData.Motor.Doc.DesktopModelSDKUrl
        // }
        // {
        //     image:'',
        //     name:'',
        //     url:''
        // }
    ]
}
