import "@babel/polyfill"
import * as React from 'react'
import * as ReactDOM from 'react-dom';
import LBDP_Header from './components/Header/Header'
import LBDP_Footer from './components/Footer/Footer'
import LBDP_Body from './components/Body/Body'
import './asset/common/style/common.less'
import './asset/declare.ts'

//配置信息
import { NavItems } from './components/Navigation/Nav';

class LBDP_WebSite extends React.Component {
    public render(): JSX.Element {
        return (
            <div>
                {/** 头部*/}
                <LBDP_Header />
                {/** 主体 */}
                <LBDP_Body  data={NavItems} history={null} location={null} match={null}/>
                {/** 尾部 */}
                <LBDP_Footer />
            </div>
        );
    }
}

ReactDOM.render(
    <LBDP_WebSite />,
    document.getElementById('root')
);

