import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab, {TabProps} from '@material-ui/core/Tab';
import MarkDown from '../Markdown/Markdown';
import '../../asset/declare'

interface Character {
    Text: string,
    Tab: string,
    TabHover: string,
    md: string,
    Image: string
}

interface CharacterKitProps {
    data: Character[]
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: -670,
            height: 130,
            display: 'flex'
        },
        container: {
            height: 540,
            marginLeft:50,
            // width: '200',
            // backgroundColor: 'transparent',
        },
        character:{
            margin:'0px auto',
            paddingLeft:40,
            width: 810,
            height: 360,
            backgroundColor: 'rgb(245, 245, 245)'
        },
        charaImg:{
            margin:'40px',
            marginRight: 35,
            float:'left'
        },
        markdown:{
            float:'right',
            width: 800,
            height: 360,
            marginTop: 42,
            marginBottom: 49,
            overflowY:'scroll',
            '& h4':{
                fontSize: '22px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                letterSpacing: '0px',
                color: '#333333',
                marginBottom: 20
            },
            '& h6':{
                fontSize: '16px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                letterSpacing: '0px',
                color: '#333333',
                marginBottom: 4,
            },
            '& p':{
                fontSize: '14px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                lineHeight: '22px',
                letterSpacing: '0px',
                color: '#666666'
            }
        }
    }),
);

interface TabContainerProps {
    children?: React.ReactNode;
    dir?: string;
}

function TabContainer({children, dir }: TabContainerProps) {
    const classes = useStyles({});
    return (
        <div className={classes.character}>
            {children}
        </div>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
    root: {
        height: 360,
        width:360,
        overflow:'visible'
    },
    fixed:{
        overflow: 'visible !important'
    },
    flexContainer: {
        height: 130
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: 13,
        '& > div': {
            width: 0,
            height: 0,
            borderTop:' 8px solid transparent',
            borderLeft: '14px solid #b7945d',
            borderBottom:' 8px solid transparent',
            position: 'absolute',
            top:30,
            right:'-40px'
        },
    },
})((props: StyledTabsProps) => <Tabs {...props}  orientation="vertical" TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(() =>
    createStyles({
        root: {
            width:310,
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            letterSpacing: '0px',
            color: '#666666',
            margin: '0 auto',
            marginBottom: 0,
            paddingTop: 0,
            paddingLeft:50,
            opacity: 1,
            '&:focus': {
                opacity: 1,
                color: '#333333'
            },
        },
        selected:{
            backgroundColor: 'rgb(245, 245, 245)',
            '& > span':{
                color: '#b7945d'
            }
        },
        wrapper:{
            alignItems:'flex-start',
            display: 'flex',
            flexDirection: 'inherit',
            justifyContent: 'flex-start',
            marginTop:14,
            lineHeight: '40px',
            '& > span':{
                width: 1,
                height:40, 
                backgroundColor: 'rgb(102, 102, 102)',
                display:'block',
                margin: '0 15px',
            }
        }
    }),
)((props: TabProps) => <Tab disableRipple {...props} />);

let curTab :number = 0;
function TabImage(tabImg: string, tabImg_hover: string, index: number) : JSX.Element {
    const [value, setValue] = React.useState(tabImg);

    function handleEnter(_event: React.ChangeEvent<{}>) {
        if(curTab == index) return;
        setValue(tabImg_hover);
        
    }
    function handleLeave(_event: React.ChangeEvent<{}>) {
        setValue(tabImg);
    }
    return (
        <>
        <img src={curTab == index ? tabImg_hover : value}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            height='40px'
            width="40px"
            style={{marginBottom: 8,objectFit:'contain'}}
        />
        <span></span>
        </>
    )
}

export default function CharacterKit(props: CharacterKitProps) {
    const classes = useStyles({});
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    curTab = value;

    function handleChange(_event: React.ChangeEvent<{}>, newValue: number) {
        setValue(newValue);
        curTab = newValue;
    }

    function handleChangeIndex(index: number) {
        setValue(index);
        curTab = index;
    }

    function getTabs(): JSX.Element[] {
        let ret: JSX.Element[] = [];
        for (let i = 0; i < props.data.length; ++i) {
            const curData = props.data[i];
            ret.push(
                <StyledTab icon={TabImage(curData.Tab, curData.TabHover, i)} label={curData.Text} key={curData.Text} value={i}/>
            );
        }
        return ret;
    }

    function getTabContainers(): JSX.Element[] {
        let ret: JSX.Element[] = [];
        for (let i = 0; i < props.data.length; ++i) {
            const curData = props.data[i];
            ret.push(
                <TabContainer dir={theme.direction} key={curData.Text}>
                    {/* <img src={curData.Image} width='540px' height='380px' className={classes.charaImg}/> */}
                   <MarkDown className={classes.markdown}>{curData.md}</MarkDown>
                </TabContainer>
            );
        }
        return ret;
    }

    return (
        <div className={classes.root}>
            <StyledTabs
                value={value}
                onChange={handleChange}
            >
                {getTabs()}
            </StyledTabs>
          
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
                className={classes.container}
            >
                {getTabContainers()}
            </SwipeableViews>
        </div>
    );
}

