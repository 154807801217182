import * as React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import '../../asset/declare'
import waitImg from './default.png'


interface ModalWaitTipProps {
    obj: JSX.Element,
    className?: string
}

const modalStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 228,
      height: 156,
      padding: theme.spacing(4),
      outline: 'none',
    },
  }),
);

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export default function ModalWaitTip(props : ModalWaitTipProps) : JSX.Element {
    const classes = modalStyles({});
    const [open, setOpen] = React.useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <a onClick={handleOpen} style={{cursor: 'pointer'}} className={props.className}>
                {props.obj}
            </a>
            <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={handleClose}
            >
                <div style={modalStyle}  className={classes.paper}>
                    <img src={waitImg} />
                </div>
            </Modal>
        </React.Fragment>
    );
}