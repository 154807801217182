import * as React from 'react'
import { Route, HashRouter } from 'react-router-dom';
import { NavItem, NavProps } from '../Navigation/Navigation'
import Home from '../Home/Home'
import Motor_SDK from '../Product/Motor-sdk'
import Builder_SDK from '../Product/Builder-sdk'
import Case from '../Case/Case'
import Example from '../Example/Example'
import Price from '../Price/Price'
import Documentation from '../Documentation/Documentation'
import ScrolltoTop from './ScrolltoTop'

export default class LBDP_Body extends React.Component<NavProps> {
    constructor(props: NavProps){
        super(props);
    }
    public render(): JSX.Element {
        return (
            <HashRouter >
                <ScrolltoTop>
                    <Route key='/' exact path='/' component={Home} />
                    {this.createRoute(this.props.data)}
                </ScrolltoTop>
                
            </HashRouter>
        );
    }

    private createRoute(items: NavItem[]): JSX.Element[] {
        let ret: JSX.Element[] = [];
        items.forEach((navItem) => {
            if (navItem.hasSubMenu) {
                let arrayNav: JSX.Element[] = this.createRoute(navItem.NavItems);
                arrayNav.forEach((element) => {
                    ret.push(element);
                });
            }
            else {
                ret.push(<Route key={navItem.id} path={navItem.path} component={this.getComponent(navItem.path)} />);
            }
        });
        return ret;
    }

    private getComponent(path: string): React.ComponentClass {
        let retComponent = null;
        switch (path) {
            case "/Product/Motor_SDK": {
                retComponent = Motor_SDK;
                break;
            }
            case "/Product/Builder_SDK": {
                retComponent = Builder_SDK;
                break;
            }
            case "/Case": {
                retComponent = Case;
                break;
            }
            case "/Example": {
                retComponent = Example;
                break;
            }
            case "/Price": {
                retComponent = Price;
                break;
            }
            case "/Documentation": {
                retComponent = Documentation;
                break;
            }
            default: retComponent = Home;
        }
        return retComponent;
    }
}