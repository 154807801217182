const CaseData = require('caseData')

export const brief = ``

export const CaseConfig = [
    {
        md: CaseData.Case1_MD,
        image: 'asset/Case/res/case1.jpg'
    },
    {
        md: CaseData.Case2_MD,
        image: 'asset/Case/res/case2.jpg'
    },
    {
        md: CaseData.Case3_MD,
        image: 'asset/Case/res/case3.jpg'
    },
    {
        md: CaseData.Case4_MD,
        image: 'asset/Case/res/case4.jpg'
    },
    {
        md: CaseData.Case5_MD,
        image: 'asset/Case/res/case5.jpg'
    },
    {
        md: CaseData.Case6_MD,
        image: 'asset/Case/res/case6.jpg'
    }
]

