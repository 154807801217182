import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MarkDown from '../Markdown/Markdown';
import '../../asset/components/Case/style/case.less'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            height: 460,
        },
        paper: {
            margin: '50px auto',
            height: 460,
            width: 366,
            boxShadow: '0px 2px 20px 0px rgba(241, 241, 241, 0.9)',
            backgroundColor: "white"
        },
        control: {
            padding: theme.spacing(2),
        },
        markdownBox:{
            width: "100%",
            height: 156,
            marginTop: 25,
            overflowY:'hidden',
            "&:hover":{
                overflowY: 'scroll'
            },
        },
        markdown:{
            marginLeft: 30,
            width: 306,
            '& h4':{
                fontSize: '18px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                letterSpacing: '0px',
                color: '#333333',
                textAlign: 'center',
                marginBottom: 26
            },
            '& p':{
                fontSize: '14px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                lineHeight: '22px',
                letterSpacing: '1px',
                color: '#666666'
            }
        }
    }),
);

interface CaseKitItem{
    md: string,
    image: string
}

interface CaseKitProps {
    data:CaseKitItem[]
}

export default function CaseKit(props: CaseKitProps) {
    const classes = useStyles({});

    function GetCase(): JSX.Element[]
    {
        let ret : JSX.Element[] = [];
        let tmp  : JSX.Element[] = [];
        let whiteBG : boolean = true;
        for(let i = 0; i < props.data.length; ++i){
            const curData=props.data[i];
            tmp.push(<Grid key={i} item>
                <div className={classes.paper} key={curData.image}>
                    <img src={curData.image} width='367px' height='235px'/>
                    <div className={classes.markdownBox}>
                        <MarkDown className={classes.markdown} >{curData.md}</MarkDown>
                    </div>
                </div>
            </Grid>);

            const next = i + 1;
            if(next < props.data.length && next % 3 == 0){
                let styleCon = 'HomeContainer_1';
                if(!whiteBG) styleCon = 'HomeContainer_2';
                ret.push(
                    <div className={styleCon} key={i}>
                        <div className='Container'>
                        <Grid container className={classes.root}  >
                            <Grid container justify="center" spacing={5} >
                                    {tmp}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                )
                whiteBG = !whiteBG;
                tmp = [];
            }
        }
        if(tmp.length > 0) {
            let styleCon = 'HomeContainer_1';
                if(!whiteBG) styleCon = 'HomeContainer_2';
                ret.push(
                    <div className={styleCon} key="last">
                        <div className='Container'>
                            <Grid container className={classes.root}  >
                                <Grid container justify="center" spacing={5} >
                                    {tmp}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                )
        }
        return ret;
    }

    return (
        <React.Fragment>
            {GetCase()}
        </React.Fragment>

    );
}