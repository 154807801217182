export const brief = {
    Motor: ``,
    Builder: ``
};

export const CharaterData = {
    Motor: {
        BorderlessWorld_MD:
            `# 无边界世界
可以创建高精细的无限制1：1孪生世界。`,
        RenderPerformance_MD:
            `# 超强渲染性能
600000000三角面片流畅运行。`,
        BimCim_MD:
            `# BIM+CIM
无损且无缝对接BIM和CIM数据`,
        CrossPlatform_MD:
            `# 安全可控
自主知识产权，数据完全可控`,
        Polyisomerization_MD:
            `# 多元异构融和   
支持多种BIM模型，GSD数据。`
    },
    Builder: {
        ProjectFormat_MD:
            `# 工程文件格式转化
支持数十种工程文件格式，在云端自动进行识别转换并保留原始文件信息，文件数据信息抽取全部自动完成。`,
        BIMMgr_MD:
            `# BIM数据管理
支持海量数据的存储及查询。根据不同的数据特性（如：资料信息、构件信息、空间信息等）可存储至文件系统、索引库、结构化存储等；提供通用的与编程语言无关的RESTful API数据接口便于用户调用集成，数据管理能够轻松搞定。`,
        LOD_MD:
            `# 模型/图纸轻量化
模型/图纸在云端进行轻量化处理，采用高效压缩算法提升压缩比，减少对计算及存储资源的占用，保证模型数据在终端可以快速高效读取并渲染。`
    }
}

export const historyTagData = [
    {
        date: '2019.07.01',
        title: '新版本',
        brief: `# Motor组件正式发布`,
        detail: `
## **Luban Motor 开发组件说明** 
****发布时间：2019-07-01****  

### **Luban Motor Editor V1.0.0**  
1. 首页

    1.1 可查看服务器工程列表信息，支持查看、下载、更新、上传、删除工程  
    1.2 支持BIM工程、场景工程两种类型工程的新建

2. BIM工程

    2.1 支持本地一个或多个PDS文件导入  
    2.2 支持对PDS模型进行选择、移动、旋转、缩放、漫游速度设置等基本操作  
    2.3 支持对工程属性、构件属性编辑，及其视角绑定、定位、删除  
    2.4 支持对楼层匹配管理操作  

3. 场景工程

    3.1  场景工程支持BIM工程、FBX模型、GSD模型数据导入  
    3.2  支持构件搜索、复制、删除  
    3.3  可对模型进行选择、移动、旋转、等比缩放、组合、取消组合、定位、移动到当前视角、漫游速度设置、显示控制等基本等操作  
    3.4  支持构件属性编辑绑定、视角绑定定位  
    3.5  提供场景模型库功能，导入模板构件可重复使用。支持模型修改、删除、加载等  
    3.6  GSD模型数据支持地形、倾斜摄影、I3S三种类型数据格式，每种类型数据支持多个url输入  
    3.7  子工程支持切层显示  

***

<br/>

### **Luban Motor Web Model SDK**   
1. MotorViewer类，初始化场景

    1.1  viewerMode属性，切换地球/无地球场景  
    1.2  loadSubproject方法，加载工程  
    1.3  setMap方法，切换地图服务  
    1.4  loadTileset方法，加载3d-Tiles（倾斜摄影或者白模）  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.1  useGradual属性，开启或关闭渐变色  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.2  shouldAnimate属性，开启或关闭建筑物上升动画  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.3  planView属性，开启或关闭俯视压平特效  
    1.5  addMouseEventListener方法，添加点选回调  
    1.6  pickPosition方法，获取鼠标位置坐标  
    1.7  pick方法，获取鼠标位置的构件  
    1.8  createVolumeRender方法，添加体渲染  
    1.9  addWater方法，添加水面多边形  
    1.10 addVideoProject方法，添加视频投影  
    1.11 marqueeEditor变量，控制框选放大  
    1.12 navigationMode变量，切换动态观察/第一人称漫游  
    1.13 roamEditor变量，控制漫游模式  

2. TiandituMap类，BingMap类，ArcGISMap类和WebMapTileService类，支持多种地图服务格式加载

3. ClippingPlaneEditor类，支持剖切操作

4. MeasureEditor类，支持测量模式

5. Component类，获取构件服务

    5.1 PropertiesTreeStructur方法，获取构件树

6. ComponentView类，操作构件视图

7. infos属性，获取构件基本信息

    7.1 setColor方法，设置构件高亮颜色  
    7.2 getBIMProperties方法，获取BIM属性

8. ProjectView类，操作工程视图

9. SceneView类，控制场景视图
    
    9.1 drawEdge属性，控制边线绘制  
    9.2 edgeColor属性，控制边线颜色  
    9.3 setBlinkComponentsFromGuids，用于根据ID闪烁构件

10. GeometryCollection类，绘制几何体

    10.1 addBox方法，绘制自定义盒子

11. MarkerCollection类，绘制点状、线状、文字、动态图形和时间动态模型等标注的绘制

12. DynamicCircle类，在tileset上添加动态圈

13. VolumeRender类，体渲染对象类

14. VideoProjection类，视频投影类

15. MarqueeEditor类，框选放大编辑器

16. OrbitControl类，无地图场景下视角控制器

17. RoamEditor类，控制第一人称漫游模式

18. CameraView类，控制相机姿态和位置，以及相机飞行

19. AutoRoamManager类，路径漫游管理器，用于录制漫游动画
  
***

<br/>

### **Luban Motor JS SDK**
1. CallBack(事件回调模块)

2. ComponentAPI(构件模块)

3. ProjectAPI(工程模块)

4. SceneAPI(场景模块)

5. SystemAPI(系统模块)

6. ThreeViewAPI(三维视图模块)

六大模块提供了基本模型，构件信息获取，构件点选回调响应，场景、工程间相互切换，相机操作，矩阵计算，对象定位，装载和卸载场景的基本操作。同时也提供了SystemAPI这一特殊模块直接与操作系统进行交互。打破了win窗口编程的技术限制，允许使用HTML+JS的方式来实现一个客户端。
`
    },
    {
        date: '2019.07.18',
        title: '新版本/新功能',
        brief: `# 编辑器V1.0.1发布`,
        detail: `
## **Luban Motor Editor V1.0.0**  
****发布时间：2019-07-18****

### **Luban Motor Editor V1.0.1**

1. 新增功能

    1.1 支持主场景直接载入静态模型  
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.1 更新前静态模型只能在子工程场景中插入，不满足复杂项目的用户需求  
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.2  更新后，用户可以根据实际需求，在主场景中直接插入静态模型  
    1.2 支持强制上传工程  
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.1 更新前，工程上传失败，需要在后台删除工程后，修改重新上传  
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.2 更新后，工程上传失败或其他问题，可以直接再次点击上传即可  
    1.3 增加链接至控制台按钮  
    1.4 增加概要模型转化方式  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.1 更新前，仅支持按照性能优先转化模型，导致部分模型转化内部破面比较严重  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.2 更新后，新增按完整度转化概要模型，在保证加载性能的基础上最大限度保证模型的完整度  

2. 优化功能

    2.1 默认显示Motor server地址  
    2.2 fbx构件进入子工程半透明               
`
    },
    {
        date: '2019.10.17',
        title: '新版本/新功能',
        brief: `
# 编辑器V1.1.0发布
# Web JS SDK接口丰富
`,
        detail: `
## **Luban Motor 开发组件升级说明**
****发布时间：2019-10-17****

### **Luban Motor Editor V1.1.0**

1. 新增功能

    1.1 支持在线升级，当服务器有新版本提供时，客户端登陆成功后，可选择下载新版本进行软件升级。  
    1.2 支持不打开工程直接导入PDS文件上传  
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.1 更新前，用户创建BIM工程必须打开场景，无法满足低配置电脑的使用  
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.2 更新后，可以在首页直接创建、管理BIM工程，保证在电脑硬件配置不足的情况下，仍然能够使用编辑器，满足客户更多样化的需求  
    1.3 工程信息卡片增加工程缩略图  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.1 更新前，用户需要查询服务器工程信息只能先下载资源，然后查询  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.2 更新后，支持用户不下载工程即可查询工程信息，并增加工程缩略图。  
    1.4 场景模型库增加云模型库  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.1 更新前，fbx模型库资源以本地资源形式存放，占用本地空间，且无法及时更新模型资源库  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.2 更新后，fbx云端存储，用户可以按需下载模型，fbx资源库可以实时更新  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5 客户端首页增加工程手动刷新按钮  
    1.6 增加静态模型在主场景中的显示控制  

2. 优化功能

    2.1 优化fbx构件资源加载逻辑，提高加载性能  
    2.2 优化工程上传逻辑，允许后台删除工程后，本地重新上传  
    2.3 优化子工程加载绑定逻辑  
    2.4 优化属性文件格式  
    &nbsp;&nbsp;&nbsp;&nbsp;2.4.1 更新前属性文件为两份xml格式文件，使用要去两份文件文件高度匹配  
    &nbsp;&nbsp;&nbsp;&nbsp;2.4.2 更新后，两份文件合为一份，格式调整为json，使用更加灵活  

***

<br/>

### **Luban Motor Web Model SDK**

1. 增加

    1.1 默认使用鼠标焦点作为缩放中心  
    1.2 默认锐化场景，优化视觉效果  
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.1 AutoRoamManager的addPose添加listen和listenId传入参数，用于插入监听点的接口  
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.2 MarkerCollection增加update方法，用于更新已有marker的属性  
    1.3 OrbitControl类  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.1 添加maximumPitch和minimumPitch，限制视角的俯仰角  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.2 添加zoomIn和zoomOut控制视口缩放  
    1.4 SceneView类  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.1 添加setHighlightComponentsFromGuids和setHighlightComponentsFromTypes，用于根据ID或者类型高亮构件  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.2 添加setColorFromGuids方法，该方法区别于fadeOutComponentsFromGuids类的方法，设置颜色后保留上次设置的颜色  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.3 添加fadeOutComponentsFromGuids和fadeInComponentsFromGuids方法，用于构件渐隐渐现  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.4 添加isolateComponentsFromGuids和isolateComponentsFromTypes，用于根据ID或者类型屏蔽或隔离构件  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.5 添加hideComponentsFromGuids、showComponentsFromGuids、hideComponentsFromTypes、showComponentsFromTypes，用于根据ID或者类型显示隐藏构件  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.6 添加setBlinkComponentsFromGuids，用于根据ID闪烁构件  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.7 添加findComponentsByBIMProperties，用于查询构件  
    1.5 MotorViewer类  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.1 添加taaEnabled传入参数，支持TAA抗锯齿，优化视觉效果  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.2 添加enableVignetteMode传入参数，用于设置渐变背景色  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.3 添加backgroundImageCss传入参数，用于设置背景图片  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.4 添加switchToPerspective和switchToOrthographic方法，切换视角正交视图和透视视图  
    1.6 添加AnimationPlayer类，用于模型动画播放  
    1.7 添加SkyBox类，用于初始化天空盒和环境贴图  
    1.8 添加BloomState类，用于控制场景泛光  
    1.9 添加ParticleSystem类和多种Emitter类，用于粒子效果  
    1.10 添加CommentEditor类和多种二维图形类用于模型批注  

2. 优化

    2.1 简化工程加载逻辑，支持SubCIM关联静态模型的直接加载  
    2.2 MotorViewer.loadSubProject方法  
    &nbsp;&nbsp;&nbsp;&nbsp;2.2.1 添加zoomToAtOnce和zoomToAtOnceCallback传入参数，支持立刻飞向工程位置，无需等待加载完成  
    &nbsp;&nbsp;&nbsp;&nbsp;2.2.2 添加lazyLoad和lazyLoadBlockSize传入参数，支持分批加载和设置分批加载的最大模型数目，避免一次性请求过多  
                
`
    },
    {
        date: '2019.12.30',
        title: '新版本/新功能',
        brief: `
# 编辑器V1.2.0发布
# Web JS SDK接口更新
# Desktop JS SDK接口更新
`,
        detail:`
## **Luban Motor 开发组件升级说明**
****发布时间：2019-12-30****

### **Luban Motor Editor V1.2.0**

1. 新增功能  

    1.1 增加绘制水体功能  
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.1 更新前，用户无法按照实际图纸绘制水体构件，只能通过贴图方式表示水体。  
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.2 更新后，用户可以在场景中自由绘制水体，且绘制完成的水体，带有波动效果。支持矩形绘制和自由绘制两种模式  
    1.2 增加绘制掩模功能  
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.1 更新前，用户无法在编辑器中二次处理倾斜摄影数据，无法选中模型，绑定属性。  
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.2 更新后，用户可以通过矩形绘制或自由绘制，对任意倾斜摄影模型进行处理，绘制出掩模，并支持绑定属性。  
    1.3 增加压平倾斜摄影模型功能  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.1 更新前，用户无法在编辑器中二次处理倾斜摄影数据，无法使用其他格式模型部分替换倾斜摄影模型。  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.2 更新后，用户可以根据自己的需求对倾斜摄影模型进行压平处理，在原位置摆放其他格式模型数据。  
    1.4 新增按照坐标位置移动构件功能  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.1 更新前，用户在处理线性工程时，根据已有的经纬度信息准确确定模型位置  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.2 更新后，在编辑器中可以直接在在模型上指定插入点，并输入精确的经纬度信息，更好的契合模型和地形。  
    1.5 增加构件下降到地面功能  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.1 更新前，用户在布置一些细小构件时，无法快速、批量的将构件摆放到地面  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.2 更新后，用户可通过下落到地面的功能，将构件快速的布置到地面，提高摆放效率  
    1.6 增加鼠标操作模式  
    1.7 云模板库增加构件类型标签筛选功能  


2. 优化功能  

    2.1 优化植物显示效果  

***

<br/>

### **Luban Motor Desktop SDK**

1. 新增  

    1.1 Project  
    1.2 Component  
    1.3 Viewer  
    1.4 Config  

2. 修改  

    2.1 修改ES5函数，为ES6风格  
    2.2 使用class代替function  

3. 替代  

    3.1 blprojectApi.js 使用Project替代  
    3.2 blcomponentApi.js 使用Component替代  
    3.3 blsceneApi.js 使用Viewer替代  
    3.4 blthreeViewApi.js 使用Viewer替代  
    
4. 支持  

    4.1 支持script方式引入  
    4.2 支持ES6语法  
    4.3 支持typescript  
    4.4 支持amd, commonjs方式引入  

***

<br/>

### **Luban Motor Web Model SDK**

1. 新增  

    1.1 MotorViewer  
    &nbsp;&nbsp;&nbsp;&nbsp;添加\`queryProject\`查询工程   
    &nbsp;&nbsp;&nbsp;&nbsp;添加\`mapCollection\`变量，用于管理地图图层  
    &nbsp;&nbsp;&nbsp;&nbsp;添加\`enableTouchControl\`参数，用于开启触屏控制   
    &nbsp;&nbsp;&nbsp;&nbsp;添加\`enableAO\`变量，用于开关AO  
    &nbsp;&nbsp;&nbsp;&nbsp;添加\`ambientOcclusion\`变量，用于控制AO强度  
    1.2 MapCollection类，用于管理地图图层  
    1.3 VideoProjection  
    &nbsp;&nbsp;&nbsp;&nbsp;支持m3u8格式的视频，需要配合videojs使用  
    1.4 ParticleSystem  
    &nbsp;&nbsp;&nbsp;&nbsp;添加\`sizeInMeters\`参数，优化例子系统显示效果  
    1.5 新增Component类，替换ComponentView  
    &nbsp;&nbsp;&nbsp;&nbsp;添加\`flatten\`属性，用于控制压平  
    1.6 新增Project类，替换SceneView和ProjectView  
    &nbsp;&nbsp;&nbsp;&nbsp;使用\`open\`和\`close\`方法，打开或者关闭工程  
    1.7 支持倾斜摄影模型的压平和掩膜  
    1.8 支持金字塔工程加载  

2. 修改   
    2.1 接口全部更新，与Desktop JS SDK统一  
    2.2 MotorViewer   
    &nbsp;&nbsp;&nbsp;&nbsp;\`taaEnabled\`参数变为\`antialias\`  

3. 弃用  
    3.1 \`SceneView\`, \`ProjectView\`, \`ComponentView\`全部弃用  
    3.2 \`Component.PropertiesTreeStructur\`静态方法改为\`project.getTreeStructure\`方法  
    
4. 修复  
    4.1 工程模型材质丢失的bug  
    4.2 多个Viewer加载同一工程报错的bug  
                        
`
    },
    {
        
        date: '2020.03.30',
        title: '新版本/新功能',
        brief: `
# 编辑器V1.3.0发布
# Web JS SDK接口更新
# Desktop JS SDK接口更新
`,
        detail:`
## **Luban Motor 开发组件升级说明**
****发布时间：2020-03-30****

### **Luban Motor Editor V1.3.0**

1. 新增功能  

    1.1 支持模型高性能转化模式  
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.1 本地转化  
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1.1.1 更新前，编辑器仅支持将PDS文件转化为高效果型，针对于超大工程，在性能方面会遇到很大挑战。  
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1.1.2 更新后，支持三种转化模式，高性能、平衡、高效果。  
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1.1.3 用户可根据工程体量及自己的需求自由的选择转化模式。  
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.2 服务器转化  
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1.2.1 更新前，所有的BIM工程均需要在本地处理完成后上传。  
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1.2.2 更新后，用户可以将PDS直接上传至服务器，然后由服务器进行转化，转化完成后，再下载到本地浏览。  
    1.2 支持平地形导入  
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.1 更新前，用户发布GSD数据，需要有卫片和地形两种格式的数据。    
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.2 更新后，支持用户仅将卫片发布，即可导入到Editor中进行使用，降低使用成本。   
    1.3 编辑器增加在web端打开工程按钮    
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.1 更新前，用户将工程上传完成后，需要进入控制台，找到对应工程，再打开预览。   
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.2 更新后，用户可以直接在编辑器中触发，在web端打开该工程。    
    1.4 支持Delete删除构件    
    1.5 支持按住Ctrl多选构件  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.1 更新前，用户在布置一些细小构件时，无法快速、批量的将构件摆放到地面  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.2 更新后，用户可通过下落到地面的功能，将构件快速的布置到地面，提高摆放效率   
    1.6 支持批量复制构件      


2. 优化功能  

    2.1 支持对搜索的构件进行批量操作，包括复制、删除、组合。  
    2.2 精细化BIM模型支持移动至当前视口。  
    2.3 工程删除异步，保证使用体验。  
    2.4 BIM工程导入场景工程中时，支持多选。  
3. 移除功能

    3.1 BIM工程不再计入工程项目数。  
    3.2 BIM工程不再在工程信息中显示工程ID。  

***

<br/>

<br/>

### **Luban Motor Web Model SDK**

1. 新增  

    1.1 添加\`AvatarControl\`类，用于控制人物的功能    
    1.2 添加\`KeyboardEventModifier\`类，用于键盘与鼠标组合的操作  
    1.3 NavigationMode  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.1 添加\`NAV_WALK\`，用于启用第一人称人物模式  
    1.4 Viewer  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.1 添加\`initializeAfterGetProjectList\`传入参数   
    1.5 AnimationPlayer  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.1 添加\`stopByName\`方法，支持根据动作名称停止动画       
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.2 \`play\`方法中增加\`name\`参数，可通过动作名称播放动画  
    1.6 ClippingPlaneEditor  
    &nbsp;&nbsp;&nbsp;&nbsp;1.6.1 添加\`setPlaneVisibility\`方法，支持控制切割面板显隐  
    1.7 Project  
    &nbsp;&nbsp;&nbsp;&nbsp;1.7.1 添加\`customMap\`变量，用于获取用户自定义的地图服务    
    &nbsp;&nbsp;&nbsp;&nbsp;1.7.2 添加\`setComponentsColorFromTree\`方法，方便用户使用构件树控制构件颜色  
    &nbsp;&nbsp;&nbsp;&nbsp;1.7.3 添加\`queryComponentsByBIMGuid\`方法，根据bimGuid查询构件  
    1.8 Component  
    &nbsp;&nbsp;&nbsp;&nbsp;1.8.1 \`infos\`中添加\`bimFloor\`字段  

2. 修改   
    2.1 优化BIM模式下视角控制的逻辑   
    2.2 在后台请求用户工程列表  
    2.3 优化根据多个\`QueryBIMCompOption\`控制样式的逻辑  
    2.4 Project  
    &nbsp;&nbsp;&nbsp;&nbsp;2.4.1 \`isolate\`相关方法屏蔽构件后，还可以设置构件颜色  
    2.5 Component  
    &nbsp;&nbsp;&nbsp;&nbsp;2.5.1 CIM工程构件的\`getProperties\`方法，返回类型从\`CompProperty[]\`变成\`CIMProperty\`  
    2.6 Viewer  
    &nbsp;&nbsp;&nbsp;&nbsp;2.6.1 \`addMouseEventListener\`方法添加第三个参数\`modifier\`，提供按键和鼠标配合使用的接口  

    
3. 修复  
    3.1 加载工程后使用鼠标中键平移，视角会飞走的bug    
    3.2 工程中水面无法加载的bug  
    3.3 工程默认视角在BIM和CIM模式下不同的bug  
    3.4 修复ktx加载不到的报错bug  
    3.5 修复viewer.destroy()方法报错的bug  
    3.6 修复切换到正交视图渲染出错的bug  
    3.7 修复homeView定位到全球不起作用的bug  
    3.8 修复工程包围盒计算偏差的bug  
    3.9 修复多个专业时，构件树控制模型显隐失败的bug  
    3.10 修复没有楼层映射时构件树显示不全的bug   
    3.11 修复鼠标拉近后不能旋转的bug  
    3.12 修复全部隐藏后无法显示的bug  
                        
`
    
    },
    {
        
        date: '2020.06.28',
        title: '新版本/新功能',
        brief: `
# 编辑器V1.4.0发布
# Web JS SDK接口更新
`,
        detail:`
## **Luban Motor 开发组件升级说明**
****发布时间：2020-06-28****

### **Luban Motor Editor V1.4.0**

1. 新增功能  

    1.1 支持导入DWG格式数据  
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.1 更新前，平台无法支持二维矢量数据、图纸等数据导入，用户在摆放模型位置时，没有可参考的点，无法精确摆放  
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.2 更新后，用户可在BIM工程内导入DWG格式图纸文件，导入后，可以在网页端和桌面端同时预览  
    1.2 支持导入OSM数据  
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.1 更新前，用户在编辑器中创建大规模场景时，只能从外部建模软件创建后导入，使用不便，且无相对位置信息  
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.2 更新后，用户可以在场景工程中直接选择导入OSM数据，在地图上框选之后，即可下载到本地，直接插入到场景中，且模型相对位置与实际位置一致   
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.3 导入的OSM模型位置可在编辑器中调整位置，在制作大场景时，可作为辅助元素，快速导入到场景中。 
    1.3 构件阵列功能    
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.1 更新前，用户在布置树木等需要反复布置的构件时，只能通过手动布置的方式，效率低。  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.2 更新后，用户可通过阵列构件的功能，快速复制构件，支持设置布置方向和布置数量，大大提高工作效率。      


2. 优化功能  

    2.1 优化场景中心点设置  
    &nbsp;&nbsp;&nbsp;&nbsp;2.1.1 将原来输入经纬度确认场景中心点的方式，调整为选择行政区划的方式  
    2.2 优化水面绘制  
    &nbsp;&nbsp;&nbsp;&nbsp;2.2.1 更新前，绘制完成的水面无法再进行位置调整，使用不便。  
    &nbsp;&nbsp;&nbsp;&nbsp;2.2.2 更新后，用户在绘制完成后，可拖动坐标球，将绘制区域移动到合适的位置后，再生成水面，大大提高操作的灵活性。    
    2.3 优化构件旋转功能   
    &nbsp;&nbsp;&nbsp;&nbsp;2.3.1 更新前，用户在旋转构件时，只能通过拖拽坐标球进行旋转，使用不便  
    &nbsp;&nbsp;&nbsp;&nbsp;2.3.2 更新后，用户在旋转构件时，可以输入角度进行构件旋转操作  
    2.4 优化楼层管理   
    &nbsp;&nbsp;&nbsp;&nbsp;2.4.1 更新前，楼层管理仅能进行合并，且是以某一个固定的PDS文件楼层为基准进行合并，无法新增或删除  
    &nbsp;&nbsp;&nbsp;&nbsp;2.4.2 更新后，楼层管理支持新增、删除和移动位置操作，操作更加便利    
    2.5 其他功能    
    &nbsp;&nbsp;&nbsp;&nbsp;2.5.1 云模板库增加已下载、未下载筛选  
    &nbsp;&nbsp;&nbsp;&nbsp;2.5.2 属性模板增加所属PDS文件功能  
    &nbsp;&nbsp;&nbsp;&nbsp;2.5.3 BIM工程转化模式调整为极速、平衡、效果模式，方便用户选择   

***

<br/>

<br/>

### **Luban Motor Web Model SDK**

1. 新增   

    1.1 提升整体渲染效果     
    1.2 添加DWG工程支持  
    1.3 添加\`AvatarRecorder\`类，用于自定义模型漫游的录制   
    1.4 MotorViewer  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.1 添加showMoon传入参数和成员变量，用于控制月亮显示隐藏  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.2 添加allowCameraUnderground成员变量，支持相机潜入地下  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.3 添加isFlashlight传入参数和成员变量，支持视角前模型始终面向光源的模式    
    1.5 Project    
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.1 添加boundingBoxLocal成员变量，获取工程在局部坐标系下的包围盒  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.2 添加localCoordinatesToWorld方法，用于将该工程下的局部坐标转换成世界坐标  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.3 open方法添加duration参数，修改飞向工程的时间  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.4 close方法添加isFlyTo参数，若为true，则飞回父工程    
    1.6 Component   
    &nbsp;&nbsp;&nbsp;&nbsp;1.6.1 添加boundingBoxLocal成员变量，获取构件在局部坐标系下的包围盒        
    &nbsp;&nbsp;&nbsp;&nbsp;1.6.2 添加getBIMProject方法，获取构件所属的BIM工程    
    1.7 ClippingPlaneEditor    
    &nbsp;&nbsp;&nbsp;&nbsp;1.7.1 添加clipTerrain传入参数，支持剖切地形   
    &nbsp;&nbsp;&nbsp;&nbsp;1.7.2 添加dragStart和dragEnd事件，监听剖切面拖动和释放  
    1.8 GeometryCollection    
    &nbsp;&nbsp;&nbsp;&nbsp;1.8.1 添加addRectangle方法，绘制沿地球表面的矩形区域    
    1.9 AvatarControls   
    &nbsp;&nbsp;&nbsp;&nbsp;1.9.1 添加autoUpdate传入参数，用于单独使用AvatarControls  
    &nbsp;&nbsp;&nbsp;&nbsp;1.9.2 添加avatarModelReady成员变量，监听avatar模型加载完成的事件  
    &nbsp;&nbsp;&nbsp;&nbsp;1.9.3 添加firstPersonControlOffset传入参数，设置视角相对于模型的位置  
    &nbsp;&nbsp;&nbsp;&nbsp;1.9.4 添加offset成员变量，用于修改视角相对于模型的位置    

2. 修改   
    2.1 MotorViewer  
    &nbsp;&nbsp;&nbsp;&nbsp;2.1.1 getProjectList方法返回变为promise     
    2.2 Project  
    &nbsp;&nbsp;&nbsp;&nbsp;2.2.1 lazyLoad加载完成的事件也放入open()返回的Promise中    
    2.3 OrbitControl  
    &nbsp;&nbsp;&nbsp;&nbsp;2.3.1 BIM模式下不会拾取地面作为旋转中心    
    2.4 优化style在传入上千个guid时设置样式的速度     
    2.5 优化水面效果     
    2.6 在cim场景下直接打开的bim工程可以由cim工程控制样式和隔离  
    2.7 倾斜摄影支持剖切     
    2.8 相机参数与客户端一致  
    
3. 修复    
    3.1 修复未加载完工程时，点击高亮模型时会报错的bug     
    3.2 修复键盘控制和人物模型控制切换的报错    
    3.3 修复关键字查询不可用的bug   
    3.4 修复自定义地图无法显示的bug  
    3.5 修复CIM场景只有地形时相机飞行报错的bug   
    3.6 修复定位初始化视角后maximumZoomDistance太小，导致BIM模式缩放失效的问题  
    3.7 修复lazyLoad加载模型不全的bug    
                        
`
    
    },
    {
        
        date: '2020.11.27',
        title: '新版本/新功能',
        brief: `
# 编辑器V1.5.0发布
# Web JS SDK V2.5.0发布
`,
        detail:`
## **Luban Motor 开发组件升级说明**
****发布时间：2020-11-27****

### **Luban Motor Editor V1.5.0**

1. 新增功能  

    1.1 模型支持渲染边框线（模型需要重新转换）    
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.1 更新前，模型无渲染边框线   
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.2 更新后，模型生成渲染边框线，可控制边线的显隐    
    1.2 构件支持捕捉点（模型需要重新转换）    
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.1 更新前构件无捕捉点信息    
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.2 更新后支持捕捉点（中点、端点、交点）   
    1.3 “按坐标移动构件”功能支持按笛卡尔坐标系移动      
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.1 更新前，模型仅支持按经纬度移动  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.2 更新后，模型支持按笛卡尔坐标系（X、Y、Z）移动        


2. 优化功能  

    2.1 按坐标移动构件”功能支持选取捕捉点（模型需要重新转化）    
    &nbsp;&nbsp;&nbsp;&nbsp;2.1.1 更新前无法选择构件上捕捉点   
    &nbsp;&nbsp;&nbsp;&nbsp;2.1.2 更新后可以选择构件上的捕捉点移动   
    2.2 移除了楼层匹配功能     
    2.3 移除了轻量化模型选项     
    2.4 移除了转化概要模型功能     
   
***

<br/>

<br/>

### **Luban Motor Web Model SDK V2.5.0**

1. 新增   

    1.1 添加UrlTemplateMap地图类，用于加载自定义模板地图服务       
    1.2 添加MapBoxStyleMap地图类，用于mapbox地图服务加载    
    1.3 添加TileMapService地图类，用于加载TMS地图服务     
    1.4 MotorViewer  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.1 添加drawEdge、edgeColor、edgeWidth成员变量，控制边线显隐、颜色和粗细  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.2 添加enableSnap变量，用于开启捕捉点  
    &nbsp;&nbsp;&nbsp;&nbsp;1.4.3 flyTo方法，添加maximumHeight，设置飞行过程中的制高点的高度  
    1.5 Project    
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.1 添加code成员变量，用户自定义第三方系统编码，用于关联第三方系统的ID  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.2 添加queryComponentsWithCode方法，用于查询关联第三方系统编码的构件  
    &nbsp;&nbsp;&nbsp;&nbsp;1.5.3 给CIM和BIM工程添加boundingRegion成员变量    
    1.6 Component   
    &nbsp;&nbsp;&nbsp;&nbsp;1.6.1 添加code成员变量，用户自定义第三方系统编码  
    1.7 BaseMap    
    &nbsp;&nbsp;&nbsp;&nbsp;1.7.1 添加show成员变量，控制地图图层显隐   
    1.8 MeasureEditor    
    &nbsp;&nbsp;&nbsp;&nbsp;1.8.1 添加snapEnabled成员变量，开关捕捉点   

2. 修改   
    2.1 Project  
    &nbsp;&nbsp;&nbsp;&nbsp;2.1.1 byOption类的函数，支持传入二维数组参数   
    &nbsp;&nbsp;&nbsp;&nbsp;2.1.2 queryComponents方法，支持传入二维数组参数  
    2.2 初始化后地球上的地图默认使用本地离线地图       
    2.3 优化页面在未激活状态下一段时间后，切换到激活状态粒子效果卡顿的问题       
    2.4 大幅优化边线显示效果       
    2.5 支持自定义坐标系的投影坐标系转成球面坐标显示（proj4）       
    2.6 BIM工程构件树按照Model工程拆分  
    2.7 优化第一人称漫游的性能（物理引擎）      
    2.8 WMTS地图服务默认格式从jpg改为png  
    2.9 用户自定义地图加载时, 不删除basemap  
    
3. 修复    
    3.1 修复场景工程中调用component.select()方法高亮bim构件时，无法清空之前选择的场景构件的bug  
    3.2 修复粒子效果的图片会出现在场景底部的bug  
    3.3 修复框选放大在某些角度下放大变缩小的问题  
    3.4 修复NavBox的destroy方法报错, 导致整个viewer.destroy报错的问题  
    3.5 修复平地形无法挖洞的bug  
    3.6 修复工程boundingSphere计算错误的问题   
    3.7 修复相机向前移动到捕捉不到锚点后，再后退就失效的bug  
    3.8 修复在场景工程移动了的bim工程，计算boundingSphere出错的问题  
    3.9 修复只有天空盒没有环境贴图时loadModel方法报错的bug  
    3.10 修复构件全部隐藏后, 按照类型无法显示构件的bug  
    3.11 修复mapcollection的lower方法把地图移动到-1的位置  

4. 弃用
    4.1 Project  
    &nbsp;&nbsp;&nbsp;&nbsp;弃用drawEdge和edgeColor方法
    4.2 MotorViewer 
    &nbsp;&nbsp;&nbsp;&nbsp;弃用getProjectList方法  

### **Luban Motor Web Model SDK 接口兼容问题**  


Motor JS SDK V2.5.0的接口兼容说明：

Motor  JS SDK V2.5.0版本由于数据优化升级，导致原来老版本的JS API接口需要兼容处理（原来所有的包含 QueryBIMCompOption 参数的接口）。  
老的QueryBIMCompOption结构如下：  
{  
    bimGuid	        bim构件id  
    floor	        楼层  
    major	        专业  
    main_type	    大类  
    sub_type	    小类  
    name	        属性名称  
}  
升级前的API接口表示按照楼层、大类、小类、属性专业关键字查询；  
新版本升级后，由于没有楼层、大类、小类、属性等关键字，统一以部位树来表示，所以原来老的接口适用于老的工程，新工程增加一个二维数组的检索参数，比如工程的结构树为：  
+XXX建筑工程  
 |  
++1F  
  |  
+++Revit  
   |  
++++墙  
    |  
+++++剪力墙  
     |  
++++++JLQ1  
   |  
++++梁  
 |  
++2F  
 |  
++3F  
如需要查询1F下面所有为JLQ1的节点以及2F所有的节点  
老的工程接口为：  
queryComponents([{  
    floor:"1F",  
    major:"Revit",  
    main_type:"墙",  
    sub_type:"剪力墙",  
    name:"JLQ1"  
},  
{  
    floor:"2F"  
}])  
或者老的工程也可以使用下面的新接口。  
新的工程接口为：  
queryComponents([["1F","Revit","墙","剪力墙","JLQ1"],["2F"]]);  

如下JS API接口需要兼容处理：  
queryComponents(QueryBIMCompOption optionsopt)  
setComponentsVisibilityByOption(QueryBIMCompOption optionsopt)  
setComponentsColorByOption(QueryBIMCompOption optionsopt)  
selectComponentsByOption(QueryBIMCompOption optionsopt)  
resetComponentsDefaultColorByOption(QueryBIMCompOption optionsopt)  
isolateComponentsByOption(QueryBIMCompOption optionsopt)  
fadeOutComponentsByOption(QueryBIMCompOption optionsopt)  
fadeInComponentsByOption(QueryBIMCompOption optionsopt)  


                        
`
    
    },
    {
        
        date: '2021.09.13',
        title: '新版本/新功能',
        brief: `
# Motor Web 编辑器V1.0.0发布
# 
# Web JS SDK V3.0.0发布
`,
        detail:`
## **Luban Motor 开发组件升级说明**
****发布时间：2021-09-13****

### **Luban Motor Web Editor V1.0.0**

1. 新增功能  

    1.1 模型管理    
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.1 支持批量上传文件并进行服务器轻量化操作（支持格式如下：*.RVT、*.PDS、*.FBX、*.DWG、*.IFC、*.LBG）   
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.2 支持链入已发布的GIS模型（DEM、DOM、倾斜摄影）   
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.2 支持在线预览处理成功的模型并分享链接    
    1.2 模板管理      
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.1 支持导入*.FBX文件并进行服务器轻量化操作    
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.2 支持在工程中引用处理成功的模板文件   
    1.3 工程管理          
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.1 支持创建工程并进行在线编辑  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.2 支持导入处理成功的BIM模型、FBX模型、矢量图模型  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.3 支持导入处理成功的GIS模型，支持设置多个DOM叠加显示优先级  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.4 支持创建模型集合，一个模型集合可作为一个数据节点管理和应用  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.5 支持构件列表的搜索、删除、移入/移出集合等操作  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.6 支持对导入的BIM模型、FBX模型、矢量图模型进行替换操作  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.7 可对模型进行选择、移动、按基点移动、旋转、等比缩放、定位、移动到当前视口、下落到底面、按坐标移动、阵列操作  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.8 支持构件重命名、构件视角绑定及定位、绑定操作基点、设置构件自定义ID  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.9 支持绑定工程默认视口、修改项目地点及工程投影坐标系参数  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.10 提供模板库功能，导入开发者账号下的用户模板，模板构件可重复引用，支持导入后模板构件的修改、删除、复制等，支持导入鲁班提供的系统模板构件  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.11 支持绘制地形洞（DEM）、压平(DEM、倾斜摄影)、掩模（倾斜摄影），对GIS模型进行简单处理  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.12 支持绘制水体  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.13 支持编辑中的前进、回退操作（暂不支持属性）  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.14 支持阳光强度、光源方向、环境贴图、抗锯齿、边线、高亮颜色、背景色设置  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.15 提供鼠标/键盘控制  
    &nbsp;&nbsp;&nbsp;&nbsp;1.3.16 支持已保存的工程在线预览并分享链接             
   
***

<br/>

<br/>

### **Luban Motor Web Model SDK V3.0.0**

详见：https://lbdp.lubansoft.com/reference/v3.0/motor-web-api/index.html  


### **此版本与Motor之前版本的兼容问题**  
注意：  
1. 此版本与Motor之前任何版本不能兼容，可视为一套全新的平台  
2. 数据重新调整，之前任何版本的数据不能升级到新的版本  
3. JS API接口全新调整，和之前任何版本不能兼容  
`
    },
    {
        
        date: '2022.01.10',
        title: '新版本/新功能',
        brief: `
# Motor Web 编辑器V1.2.0发布
# 
# Web JS SDK V3.2.0-Release发布
`,
        detail:`
## **Luban Motor 开发组件升级说明**
****发布时间：2022-01-10****

### **Luban Motor Web Editor V1.2.0**

1. 新增功能  

    1.1 模板构件按区域/直线布置    
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.1 更新前不支持批量布置模板构件   
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.2 更新后可以绘制直线，输入构件数量后按固定间距批量生成一定数量的模板构件   
    &nbsp;&nbsp;&nbsp;&nbsp;1.1.2 更新后可以绘制区域，在区域内随机生成一定数量的模板构件    
    1.2 模型树和GIS树支持自定义排序     
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.1 更新前不支持模型树和GIS树的自定义排序    
    &nbsp;&nbsp;&nbsp;&nbsp;1.2.2 更新后可按自定义顺序对模型树和GIS进行排序  

2. 优化功能  

    2.1 绑定操作基点后，以选择的基点为旋心  
    2.2 按坐标移动构件支持选择非捕捉点为基点  
    2.3 优化了模型精细层级加载速度  

3. 修正

    3.1 修正了某些情况下删除被工程中引用的模型结果错误的问题
    3.2 修正了某些情况下切换卫片范围设置报错的问题  
    3.3 修正了隔离构件命令结果错误的问题  
   
***

<br/>

<br/>

### **Luban Motor Web Model SDK V3.2.0**

API文档详见：https://lbdp.lubansoft.com/reference/v3.0/motor-web-api/index.html  

在线DMEO详见：https://lbdp.lubansoft.com/code-box/motor/index.html

1. 新增  

    1.1 粒子系统特效接口  
    1.2 支持动态UV材质贴图（绘制动态轨迹）  
    1.3 雨雪天气特效  
    1.4 地图层级控制  
    1.5 CZML Clock动画  
    1.6 移动端触控操作适配  
    1.7 绑定视角，新增distance参数用于控制：无法锚点时，深度距离，-1表示始终锚点在0平面上（使用DWG图纸场景）  
    1.8 按需加载模型工程  
2. 优化/修正   

    2.1 按照部位树路径显隐构件，父子节点同时操作是，前次操作被覆盖  
    2.2 优化SDK打包方式，适用于NPM镜像推包后，导致Motor中已声明变量，无法识别  
    2.3 优化多路视频投影

`
    },
]
